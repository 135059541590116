import { render, staticRenderFns } from "./PriceEditVariant.vue?vue&type=template&id=016a8ccd&scoped=true&"
import script from "./PriceEditVariant.vue?vue&type=script&lang=js&"
export * from "./PriceEditVariant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "016a8ccd",
  null
  
)

export default component.exports