<template>
  <main
    class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8"
  >
    <div class="py-16">
      <div class="text-center">
        <p class="text-sm font-semibold text-action uppercase tracking-wide">404 error</p>
        <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
          Product not found.
        </h1>
        <p class="mt-2 text-base text-gray-500">
          Sorry, we couldn’t find the product you’re looking for.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style></style>
