<template>
  <div
    class="relative w-64 h-64 shadow-md ml-3 overflow-hidden mb-5 rounded-xl bg-white flex flex-col justify-between"
    :class="getMargin"
  >
    <div class="absolute left-1 top-1 flex">
      <div v-for="rating in [0, 1, 2, 3, 4]" :key="rating">
        <svg
          class="flex-shrink-0 h-5 w-5"
          :class="productInfo.averageRating > rating ? 'text-action' : 'text-gray-300'"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
          />
        </svg>
      </div>
    </div>
    <!-- Image -->
    <router-link :to="`/admin/products/${this.productInfo._id}`" class="rounded-t-lg">
      <div class="overflow-hidden my-5 mx-auto flex items-center justify-center h-36 w-36">
        <img
          v-if="productInfo.image"
          width="170px"
          height="170px"
          loading="auto"
          :src="productInfo.image"
        />
        <img
          v-else-if="variant.image"
          width="170px"
          height="170px"
          :src="variant.thumbnail ? variant.thumbnail : variant.image"
        />
        <img
          v-else
          width="170px"
          height="170px"
          loading="auto"
          src="/images/icons/image-coming-soon.png"
          alt="No image available"
        />
      </div>
      <!-- Info -->
    </router-link>
    <div
      class="w-48 font-medium mx-4 break-words whitespace-normal"
      style="word-wrap: break-word; word-break: break-word"
    >
      {{ productInfo.title }}
    </div>
    <div class="ml-4">
      <p class="text-gray-500 text-sm -mt-1">
        purchased:
        {{ productInfo.purchased }}
      </p>
    </div>
    <!-- Button -->
    <form
      @submit.prevent="removeSuggestion"
      class="bg-red-300 w-12 h-12 rounded-br-xl rounded-tl-md absolute bottom-0 right-0 flex items-center justify-between text-2xl md:text-xl"
    >
      <button type="submit" class="text-2xl text-white px-4">
        <font-awesome-icon icon="times" />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    productInfo: Object,
    index: Number,
    length: Number,
  },
  data: function () {
    return {
      numForCart: 1,
      selectedVariant: 0,
      colors: new Map(),
    };
  },
  methods: {
    removeSuggestion() {
      this.$emit('removeSuggestion', this.productInfo);
    },
  },
  computed: {
    getMargin() {
      if (this.index == 0) {
        return 'ml-8 sm:ml-4';
      }
      if (this.index == this.length - 1) {
        return 'mr-8 sm:mr-4';
      }
      return '';
    },
    getImage() {
      if (this.productInfo.image) {
        return this.productInfo.image;
      } else {
        return this.variant.image;
      }
    },
    variant() {
      return this.productInfo.variants[this.selectedVariant];
    },
  },
  mounted() {},
};
</script>

<style>
img.variant {
  width: auto;
  height: auto;
}
</style>
