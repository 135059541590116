<template>
  <div>
    <h3 class="text-primary mb-6">Add {{ tag.type }}</h3>
    <div class="w-full justify-center text-left">
      <div class="flex flex-wrap justify-center">
        <div class="flex flex-col mr-2">
          <label for="tag-type" class="block text-sm font-medium text-gray-700">Type</label>
          <select
            id="tag-type"
            v-model="tag.type"
            class="shadow-sm border focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            <option value="Category">Category</option>
            <option value="Subcategory">Subcategory</option>
            <option value="Tag">Tag</option>
            <option value="Collection">Collection</option>
          </select>
        </div>
        <div class="flex flex-col">
          <label for="tag-name" class="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            id="tag-name"
            class="border focus:border-blue-500 block w-full rounded-none rounded-md px-2 sm:text-sm border-gray-300"
            v-model="tag.name"
          />
        </div>
      </div>

      <div class="flex flex-col mt-4 w-64 mx-auto" v-if="tag.type === 'Subcategory'">
        <label for="tag-category" class="block text-sm font-medium text-gray-700"> Category </label>
        <select
          class="shadow-sm border focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          id="tag-category"
          v-model="tag.category"
        >
          <option v-for="category in categories" :key="category._id" :value="category.name">
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="flex flex-col text-center">
        <label class="-mb-6 mt-6">
          <span v-if="tag.type === 'Subcategory' || tag.type === 'Category'">Image</span>
          <span v-else class="font-normal">
            (No need to add an image file for {{ tag.type }}s)
          </span>
        </label>
        <Uploader
          :title="false"
          @setValues="setTagImage"
          @noFileSave="saveTag"
          @close="close()"
          buttonText="Save"
        ></Uploader>
        <div class="text-left -mt-8 w-32">
          <button @click="deleteTag()" class="text-red">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from '@/components/shared/Uploader';

export default {
  data: function () {
    return {};
  },
  props: {
    tag: Object,
  },
  computed: {
    categories() {
      return this.$store.getters['tags/getTags']({ type: 'Category' });
    },
  },
  watch: {},
  mounted() {},
  methods: {
    saveTag() {
      if (this.tag.name === null || this.tag.type === null) {
        this.$store.commit('setError', {
          message: 'Tag Name needs to be filled out.',
          color: 'red',
        });
        return;
      }
      if (this.tag.type === 'Subcategory' && !this.tag.category) {
        this.$store.commit('setError', {
          message: "Can't save subcategory without a category.",
          color: 'red',
        });
        return;
      }

      this.$store.dispatch('tags/addNewTag', this.tag);
      this.close();
    },

    setTagImage(val) {
      this.tag.image = val.thumbnail;
      this.saveTag();
    },
    deleteTag() {
      this.$store.dispatch('tags/deleteTag', this.tag._id);
      this.close();
    },
    close() {
      this.$emit('closeModal');
    },
  },
  components: {
    Uploader,
  },
};
</script>
<style scoped></style>
