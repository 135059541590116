<template>
  <div class="grid grid-cols-12 gap-2 w-full my-1">
    <div class="col-span-2">{{ variant.productNum }}</div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        @change="setVariantPricing(variant, vendor)"
        v-model="variant.msrp"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierOne"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierTwo"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierThree"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierFour"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierFive"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.tierSix"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="flex items-center">
      <span class="absolute ml-1">$</span>
      <input
        type="number"
        v-model="variant.cost"
        class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        style="padding-left: 15px"
      />
    </div>
    <div class="col-span-2">
      <DualButton
        :options="variantOptions"
        @execute="handleButtonMethods($event)"
        position="right"
      />
    </div>
  </div>
</template>

<script>
import setVariantPrices from '@/assets/functions/pricing.js';
import DualButton from '@/components/shared/DualButton';
export default {
  data: function () {
    return {
      variantOptions: [
        { name: 'Save', text: 'Save variant.' },
        {
          name: 'Overwrite',
          text:
            'Save this variant. If the cost of this variant has changed, the percentage difference between the new cost and the old cost will be applied to all users who have this variant in their special priced items.',
        },
      ],
    };
  },
  props: {
    variant: { type: Object, require: true },
    vendor: { type: String, require: true },
  },
  computed: {},
  watch: {},
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Overwrite':
          this.overWrite();
          break;
        default:
          this.save();
          break;
      }
    },
    overWrite() {
      this.$store.dispatch('company/overWriteVariants', { variants: [this.variant] });
      this.$store.commit('setError', {
        color: 'yellow',
        message: `Updated ${this.variant.productNum}`,
      });
    },
    save() {
      this.$store.dispatch('variants/saveVariant', this.variant);
    },
    setVariantPricing(variant, vendor) {
      this.costChanged = false;
      this.altered = true;
      setVariantPrices(variant, vendor);
    },
  },
  mounted() {},
  components: {
    DualButton,
  },
};
</script>
<style scoped></style>
