<template>
  <div>
    <div class="flex">
      <DualButton :options="buttonOptions" @execute="handleButtonMethods($event)" />
    </div>
    <PopUp v-if="showUploader" title="Mass Uploader" @close="showUploader = false">
      <div v-if="showInstructions" class="overflow-auto h-128">
        <p class="my-2">
          <strong>Product ID:</strong> Is used for updating products. This value is an automatically
          generated value from the server. If you are uploading a new variant or product, the new
          item shouldn't have a product id.
        </p>
        <p class="my-2">
          <strong>Title:</strong> Is the unique identifier that is used to associate multiple
          variants with eachother. If you want two variants to fall under the same product, the
          Title has to be the same between them and they have to be in sequential order.
        </p>
        <p class="my-2">
          <strong>Part Number:</strong> Is the unique Identifier for the variants and is required.
          If the title and the Part Number is blank, the server will conclude that the upload is
          finished and will exit. The part Number is used to identify if the variant is already in
          the database, so if you are adding a new variant with a Part Number that is not uniqe, it
          will not add the product but will overwrite the pre-existing variant.
        </p>
        <p class="my-2">
          <strong>Description:</strong> Is the value that will be shared across the entire product.
          If the description is different for the variants inside of a product at time of upload,
          only the first description will be used and the others will be discarded.
        </p>
        <p class="my-2">
          <strong>Type:</strong> Is in regards to the product type. Only one type per product will
          be used and if one variant has a different type then only the first variants listed type
          will be used.
        </p>
        <p class="my-2"><strong>Flammable:</strong> Is used internally for shipping purposes.</p>
        <p class="my-2">
          <strong>Publish Product:</strong> If set to TRUE then the product will be displayed
          publicly as soon as it is live.
        </p>
        <p class="my-2">
          <strong>Sale:</strong> If set to TRUE the products price will drop down to tier 1 instead
          of msrp for non-signed in users.
        </p>
        <p class="my-2"><strong>DropShip:</strong> Is used internally for shipping purposes.</p>
        <p class="my-2">
          <strong>Vendor:</strong> If the vendor that you upload is already on the site, then it
          will be displayed on the product inside of the editor. If it is not inside of the system,
          then you will have to upload the vendor manually inside of the product editor or inside of
          the the user Editor.
        </p>
        <p class="my-2">
          <strong>Tags:</strong> Is a list that is ',' or '*' delimeted. Either will work for
          upload, but for export only '*' will be used. If the tag is already in the system before
          you upload, then it will automatically be displayed. If it isn't inside of the system,
          then you will have to add it manually inside of the product Editor.
        </p>
        <p class="my-2"><strong>Categoris:</strong> Are the same as tags.</p>
        <p class="my-2"><strong>Subcategories:</strong> Are the same as tags.</p>
        <p class="my-2">
          <strong>Option Names:</strong> Option names are a product attribute and each product can
          only have one name per option.
        </p>
        <p class="my-2"><strong>Option Values:</strong> Are used on the variants.</p>
        <p class="my-2">
          <strong>On Hand:</strong> Is the number of a variant that you have in stock to sell.
        </p>
        <p class="my-2"><strong>MSRP:</strong> Is used to calculate all of the tiers.</p>
        <p class="my-2">
          <strong>Tiers:</strong> Can't be explicitly stated, it has to be calculated based on the
          MSRP.
        </p>
        <p class="my-2"><strong>Our Cost:</strong> Your cost from the vendor.</p>
        <p class="my-2">
          <strong>Whole Sale Qty:</strong> Is the number that a user has to buy before they get a
          larger discount.
        </p>
        <p class="my-2">
          <strong>Piece Size:</strong> Number by which a product is packaged for sale.
        </p>
        <p class="my-2"><strong>LB:</strong> Pounds</p>
        <p class="my-2"><strong>OZ:</strong> Ounces</p>
        <p class="my-2">
          <strong>Box Qty:</strong> Number by which a product is received by vendor.
        </p>
        <p class="my-2"><strong>Bar Code:</strong> Not yet implimented</p>
        <p class="my-2">
          <strong>Publish Variant:</strong> If set to TRUE, the variant will be published along with
          the product. If the product is set to publish but no variants are set to publish, there
          will be an error.
        </p>
        <p class="my-2">
          <strong>Product Image:</strong> Is the pretty picture that will be associated with the
          entire product.
        </p>
        <p class="my-2">
          <strong>Variant Image:</strong> Is the picture that will be associated with a given
          variant.
        </p>
      </div>
      <form @submit.prevent="upload" enctype="multipart/form-data" class="grid grid-cols-2">
        <label for="showInstr" class="col-span-2 text-center"
          ><input type="checkbox" name="" id="showInstr" v-model="showInstructions" /> Show
          Instructions</label
        >
        <input type="file" name="file" @change="fileChanged" class="my-2" />
        <button type="submit" class="btn-action rounded w-32 mx-auto">Upload</button>
      </form>
    </PopUp>
  </div>
</template>

<script>
import PopUp from '@/components/shared/DesktopModal';
import DualButton from '@/components/shared/DualButton';
import axios from 'axios';
export default {
  data: () => {
    return {
      showUploader: false,
      file: null,
      showInstructions: false,
      buttonOptions: [
        {
          name: 'Import',
          text: 'Import a csv file',
        },
        {
          name: 'Export',
          text: 'If no products are selected, all products will be exported.',
        },
        {
          name: 'Export Shopify',
          text: 'If no vendor or product is selected, all products will be exported.',
        },
      ],
    };
  },
  props: {
    selectedProducts: Array,
    selectedVendor: String,
    category: String,
    subCategory: String,
  },
  computed: {},
  watch: {},
  methods: {
    handleButtonMethods(option) {
      switch (option.name) {
        case 'Import':
          this.showUploader = true;
          break;
        case 'Export':
          this.getProducts();
          break;
        default:
          this.exportShopify();
          break;
      }
    },
    /**
     * Methods for file uploading and downloading
     */
    fileChanged(event) {
      this.file = event.target.files[0];
    },

    upload() {
      if (this.file === null) {
        this.$store.commit('setError', {
          message: 'No file selected',
          color: 'red',
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file, 'massUpload.csv');
      if (this.$store.dispatch('admin/upload', formData)) {
        this.showUploader = false;
      }
    },
    async getProducts() {
      if (this.selectedProducts.length != 0) {
        if (
          !confirm(
            'Items have been selected, Exporting data will ONLY export the selected products, do you wish to continue?'
          )
        ) {
          return;
        }
      } else {
        if (
          !confirm(
            'Items have not been selected, Exporting data will export all products, do you wish to continue?'
          )
        ) {
          return;
        }
      }
      this.$store.commit('setError', {
        message:
          'This may take a few moments. Products are being retrieved from the database to ensure they are up to date. Do not leave this page, Do not refresh the page.',
        color: 'yellow',
        duration: 0,
      });
      const productIds = this.selectedProducts.map((product) => product._id);
      try {
        const file = await axios.put('/api/v1/products/export/', productIds);
        const csv = file.data;
        const fileName = 'Products ' + new Date().toLocaleString('en-US') + '.csv';
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit('setError', {
          message: 'Export has failed.',
          color: 'red',
          duration: 0,
        });
      }
    },
    async exportShopify() {
      if (this.selectedProducts.length === 0 && this.selectedVendor.length === 0) {
        if (
          !confirm(
            'Vendor or Items have not been selected, Exporting data will export all products, do you wish to continue?'
          )
        ) {
          return;
        }
      }
      this.$store.commit('setError', {
        message:
          'This may take a few moments. Products are being retrieved from the database to ensure they are up to date. Do not leave this page, Do not refresh the page.',
        color: 'yellow',
        duration: 0,
      });
      try {
        const products = this.selectedProducts.map((product) => product._id);
        const file = await axios.put('/api/v1/products/export-shopify', {
          vendor: this.selectedVendor,
          products,
          category: this.category,
          subCategory: this.subCategory,
        });
        const csv = file.data;
        const fileName = 'Vendor Products ' + new Date().toLocaleString('en-US') + '.csv';
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit('setError', {
          message: 'Export has failed.',
          color: 'red',
          duration: 0,
        });
      }
    },
  },
  components: {
    PopUp,
    DualButton,
  },
  mounted() {},
};
</script>
<style scoped></style>
