<template>
  <div class="bg-gray-200 px-3 relative">
    <div v-if="loading">
      <Loading />
    </div>
    <ProductNotFound v-else-if="product === null" />

    <div v-else class="" :class="product.color ? getBorderColor(product.color) : null">
      <div class="absolute -top-4 right-1">
        <FieldsTab :fields="fields" @setLocalStorage="setLocalStorage()" />
      </div>
      <div class="bg-white rounded-md pr-4 md:grid md:grid-cols-6 lg:grid-cols-10 gap-4">
        <!-- Image -->
        <div class="md:grid row-span-2 place-content-center">
          <div class="mx-auto bg-white rounded-lg w-32 h-32 m-auto overflow-hidden">
            <!-- If there is a product image -->
            <div
              v-if="product.image"
              @click="showProductUploader = !showProductUploader"
              class="m-auto relative"
            >
              <div
                @click="(product.image = null), (altered = true)"
                class="cursor-pointer hover:bg-red-200 py-1 px-2 absolute right-0 top-0"
              >
                <font-awesome-icon icon="times" />
              </div>
              <img :src="product.image" alt="" />
            </div>
            <!-- If there isn't a product image -->
            <div v-else class="w-full h-full">
              <div class="h-1/2 flex items-end justify-center">
                <div
                  @click="showProductUploader = !showProductUploader"
                  class="w-12 h-12 rounded-full shadow-md bg-blue-glow-light hover:bg-blue-glow text-white text-lg flex items-center justify-center cursor-pointer"
                >
                  <font-awesome-icon icon="plus" />
                </div>
              </div>
              <div class="text-center text-sm p-2">Add image</div>
            </div>
          </div>
          <!-- End image -->
          <div class="flex w-full mx-auto mt-4 mb-1 pl-2 text-sm">
            <label for="flammable" class="block text-sm font-medium text-gray-700 text-center">
              Flammable
            </label>
            <div class="ml-auto">
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.flammable"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="product.flammable = !product.flammable"
              />
            </div>
          </div>
          <div class="flex w-full mx-auto my-1 pl-2">
            <label for="dropship" class="block text-sm font-medium text-gray-700">Dropship</label>
            <div class="ml-auto">
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.dropShip"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="
                  (product.dropShip = !product.dropShip),
                    (altered = true),
                    (product.quickbooksUpdate = true)
                "
              />
            </div>
          </div>
          <div class="flex w-full mx-auto my-1 pl-2">
            <label for="local" class="block text-sm font-medium text-gray-700">Local</label>
            <div class="ml-auto">
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.local"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="
                  (product.local = !product.local),
                    (altered = true),
                    (product.quickbooksUpdate = true)
                "
              />
            </div>
          </div>
          <div class="flex w-full mx-auto my-1 pl-2">
            <label for="isBundle" class="block text-sm font-medium text-gray-700">Bundle</label>
            <div class="ml-auto">
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="isBundle"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="
                  (isBundle = !isBundle),
                    (altered = true),
                    (product.quickbooksUpdate = true),
                    (product.type = 'Bundle'),
                    resetBundleItems()
                "
              />
            </div>
          </div>
          <div class="flex w-full mx-auto my-1 pl-2">
            <label for="variantLayout" class="block text-sm font-medium text-gray-700"
              >Variant Layout</label
            >
            <div class="ml-auto">
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.variantLayout === 1"
                :labels="{ checked: 'Dropdown', unchecked: 'Normal' }"
                :margin="3"
                @change="product.variantLayout = product.variantLayout === 0 ? 1 : 0"
              />
            </div>
          </div>
        </div>
        <div class="col-span-3 flex flex-col mt-4">
          <label for="title" class="block text-sm font-medium text-gray-700">
            {{ isBundle ? 'Bundle Name' : 'Product Name' }}
          </label>
          <input
            type="text"
            id="title"
            class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
            v-model="product.title"
            @change="altered = true"
          />
        </div>

        <div class="flex flex-col col-span-2 mt-4">
          <label for="type" class="block text-sm font-medium text-gray-700">Type</label>
          <input
            type="text"
            class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
            id="type"
            v-model="product.type"
            :disabled="isBundle"
            @change="altered = true"
          />
        </div>

        <div class="flex flex-col col-span-2 row-span-3 mt-4">
          <!-- Categories -->
          <ListAdd
            label="Categories"
            :editable="true"
            type="select"
            :selectList="categories"
            :list="product.categories"
            @addValue="addToCategories"
            @removeValue="removeFromCategories"
            @setEdit="setTagEdit"
            :create="true"
          />
          <!-- End Categories -->

          <!-- Subcategories -->
          <ListAdd
            class="mt-2"
            label="Subcategories"
            :editable="true"
            type="select"
            :selectList="subcategories"
            :list="product.subcategories"
            @addValue="addToSubcategories"
            @removeValue="removeFromSubcategories"
            @setEdit="setTagEdit"
            :create="true"
          />
          <!-- end subcategories -->

          <!-- Collection -->
          <ListAdd
            class="mt-2"
            label="Collection"
            :editable="true"
            type="select"
            :selectList="collections"
            :list="product.collections"
            @addValue="addToCollections"
            @removeValue="removeFromCollection"
            @setEdit="setTagEdit"
            :create="true"
          />
          <!-- end collections -->
        </div>
        <div class="flex flex-col col-span-2 row-span-2">
          <!-- Product Color  -->
          <div>
            <label for="color" class="block text-sm font-medium text-gray-700">color</label>
            <select
              id="color"
              name="color"
              class="mt-1 border block w-full rounded-md py-2 pl-3 pr-10 text-base border-gray-300 focus:border-blue-500 focus:outline-none sm:text-sm"
              v-model="product.color"
            >
              <option value="red">red</option>
              <option value="green">green</option>
              <option value="blue">blue</option>
              <option value="purple">purple</option>
              <option value="gray">gray</option>
            </select>
          </div>
          <!-- Product end Color  -->
          <!-- Tags -->
          <ListAdd
            label="Tags"
            :editable="true"
            type="select"
            :selectList="tags"
            :list="product.tags"
            @addValue="addTag"
            @removeValue="removeTag"
            @setEdit="setTagEdit"
            :create="true"
          />
          <!-- End tags -->
        </div>
        <div class="col-span-4 flex flex-col">
          <label for="description" class="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            type="text"
            class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
            id="description"
            v-model="product.description"
          >
          </textarea>

          <div class="flex justify-between mt-2 space-x-2">
            <!-- Start Vendor -->
            <div>
              <label for="vendor" class="block text-sm font-medium text-gray-700">Vendor</label>
              <select
                id="vendor"
                v-model="vendor"
                class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                @change="altered = true"
              >
                <option v-for="vendor in vendors" :key="vendor._id">{{ vendor.name }}</option>
              </select>
            </div>
            <!-- End Vendor -->
            <div>
              <div class="block text-sm font-medium text-gray-700" for="wholeSaleQty">
                Whole Sale
                <select class="rounded text-xs bg-transparent m-0 py-0 pr-6" id="wholeSaleNum">
                  <option :value="1">1</option>
                  <option :value="2">2</option>
                  <option :value="3">3</option>
                </select>
              </div>
              <div class="flex rounded-md shadow-sm">
                <div class="relative flex flex-grow items-stretch focus-within:z-10">
                  <input
                    type="number"
                    name="email"
                    id="email"
                    class="block w-full border rounded-none rounded-l-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    v-model="wholeSaleQty"
                    placeholder="10"
                  />
                </div>
                <button
                  type="button"
                  class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  @click="setWholeSaleQty()"
                >
                  <span>Set</span>
                </button>
              </div>
            </div>
            <div>
              <label for="video" class="block text-sm font-medium text-gray-700">
                Video iframe
              </label>
              <input
                type="text"
                v-model="product.video"
                class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                id="video"
                placeholder="https://youtube.com"
              />
            </div>
          </div>
          <div class="flex mt-2 space-x-2">
            <div class="flex-1 related-product">
              <label for="relatedProduct" class="block text-sm font-medium text-gray-700 my-2">
                Required Product
              </label>
              <div
                v-for="(relatedProduct, productIndex) in product.relatedProduct"
                :key="productIndex"
                class="relative w-full h-10"
                :class="relatedProductList[productIndex].showProducts ? 'z-2' : ''"
              >
                <input
                  @focus="relatedProductList[productIndex].showProducts = true"
                  @keydown="relatedProductList[productIndex].searchRequest = true"
                  @keyup="fetchProducts('relatedProductList', $event, productIndex)"
                  class="rounded-md border border-gray-300 my-2 w-full"
                  type="text"
                  :value="relatedProductList[productIndex].searchValue"
                />
                <i
                  class="remove"
                  v-if="relatedProductList[productIndex].searchValue"
                  @click="resetRelatedProduct(productIndex)"
                  >&times;</i
                >
                <ul
                  class="bg-white border py-2 w-full h-25 overflow-y-auto"
                  v-if="
                    relatedProductList[productIndex].loading &&
                    !relatedProductList[productIndex].products.length
                  "
                >
                  <li class="cursor-pointer p-2">Loading ...</li>
                </ul>
                <ul
                  class="bg-white border py-2 w-full h-48 overflow-y-auto"
                  v-if="
                    relatedProductList[productIndex].searchValue.length &&
                    relatedProductList[productIndex].showProducts &&
                    !relatedProductList[productIndex].loading &&
                    relatedProductList[productIndex].products.length
                  "
                >
                  <li
                    class="cursor-pointer p-2"
                    :class="{
                      'border-b-2': pIndex !== relatedProductList[productIndex].products.length - 1,
                    }"
                    v-for="(product, pIndex) in relatedProductList[productIndex].products"
                    :key="product._id + pIndex"
                    v-html="
                      boldenSuggestion(relatedProductList[productIndex].searchValue, product.title)
                    "
                    @click="selectRelatedProduct(product, productIndex)"
                  ></li>
                </ul>
              </div>
            </div>
            <div class="flex-1">
              <label
                for="required-product-text"
                class="block text-sm font-medium text-gray-700 my-2"
              >
                Required Product Text
              </label>
              <textarea
                type="text"
                class="border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                id="required-product-text"
                placeholder="Another product is required with this product."
                v-model="product.relatedProductText"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- Variants -->
      <div class="col-span-10 overflow-x-auto bg-white rounded relative w-full overflow-hidden">
        <div class="px-2 tableFixHead" :class="isBundle ? 'bundle-table' : null">
          <table class="variant-table table-fixed bg-white my-2 min-w-full">
            <thead>
              <tr>
                <th class="text-primary-dark text-sm bg-white z-1"></th>
                <th class="z-1 bg-white">
                  <button
                    @click="addOption()"
                    class="whitespace-no-wrap text-sm text-primary font-medium hover:bg-blue-200 px-2 py-1 rounded"
                  >
                    <!-- <font-awesome-icon icon="plus" /> -->
                    + Option
                  </button>
                </th>
                <th
                  v-for="(option, index) in product.options"
                  :key="index"
                  class="z-1 relative text-primary-dark font-semibold text-sm trash-can-wrapper bg-white pr-50"
                >
                  <input
                    type="text"
                    v-model="product.options[index].value"
                    class="font-semibold rounded-md border border-gray-300 w-32"
                    v-if="isGroup(option)"
                  />
                  <input
                    type="text"
                    v-model="product.options[index]"
                    class="font-semibold rounded-md border border-gray-300 w-32"
                    v-else
                  />
                  <div
                    class="absolute right-1 top-0 hover:bg-green-300 cursor-pointer px-2 py-1 mr-2 mt-px rounded-sm"
                    @click="changeOptionType(index)"
                  >
                    <font-awesome-icon
                      title="Switch to Add / Update Group"
                      v-if="!isGroup(option)"
                      icon="list"
                    />
                    <font-awesome-icon title="Switch to input option" v-else icon="edit" />
                  </div>
                  <div
                    class="absolute right-0 top-0 hover:bg-red-300 cursor-pointer px-2 py-1 mr-2 mt-px rounded-sm"
                    @click="removeOption(index)"
                  >
                    <font-awesome-icon icon="times" />
                  </div>
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white">Part Number</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.stock">On Hand</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.min">Min Qty</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.msrp">MSRP</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 1</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 2</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 3</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 4</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 5</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.tiers">Tier 6</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.cost">Our Cost</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Qty
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Price
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Qty
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Price
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Qty
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.wholeSale">
                  Sale Price
                </th>

                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.piece">
                  Piece Size
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.weight">Weight</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.boxQty">Box Qty</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.barCode">
                  Bar Code
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.qb">QB ID</th>
                <th class="z-1 text-primary-dark text-sm bg-white" v-if="fields.bin">
                  Bin Location
                </th>
                <th class="z-1 text-primary-dark text-sm bg-white">Publish</th>
                <th class="z-1 text-primary-dark text-sm bg-white"></th>
              </tr>
            </thead>
            <template v-for="(variant, variantIndex) in product.variants">
              <tbody class="h-1/2 overflow-y-auto border" :key="variantIndex">
                <tr :class="variantIndex % 2 || isBundle ? 'bg-blue-100' : null">
                  <td class="m-1 shadow my-3">
                    <div
                      class="relative h-full w-16 overflow-hidden trash-can-wrapper"
                      v-if="variant.image"
                      @click="
                        (showVariantUploader = !showVariantUploader), (currentVariant = variant)
                      "
                    >
                      <div
                        @click="(variant.image = null), (altered = true)"
                        class="cursor-pointer hover:bg-red-200 py-1 px-2 absolute right-0 top-0 trash-can"
                      >
                        <font-awesome-icon icon="times" />
                      </div>
                      <img :src="variant.image" />
                    </div>
                    <div v-else class="h-full w-16 flex items-end justify-center">
                      <div
                        @click="
                          (showVariantUploader = !showVariantUploader), (currentVariant = variant)
                        "
                        class="w-8 h-8 cursor-pointer rounded-full shadow-md bg-blue-glow-light hover:bg-blue-glow text-white flex items-center justify-center"
                      >
                        <font-awesome-icon icon="plus" />
                      </div>
                    </div>
                  </td>
                  <td class="flex my-3">
                    <button
                      class="text-primary-light text-sm rounded mx-2"
                      @click.prevent="duplicateVariant(variant, variantIndex)"
                    >
                      Duplicate
                    </button>
                  </td>
                  <td v-for="(option, optionIndex) in product.options" :key="optionIndex">
                    <button
                      class="text-primary-light text-sm rounded mx-2"
                      @click="addOrUpdateGroup(variantIndex, optionIndex)"
                      v-if="isGroup(product.options[optionIndex])"
                    >
                      Add / Update Group
                    </button>
                    <input
                      v-else
                      type="text"
                      v-model="variant.options[optionIndex]"
                      @change="altered = true"
                      class="rounded-md border border-gray-300 w-32 mx-2"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      min="0"
                      v-model="variant.productNum"
                      class="w-32 mx-2 text-center rounded-md border border-gray-300"
                      @change="(altered = true), (product.quickbooksUpdate = true)"
                    />
                  </td>
                  <td v-if="fields.stock">
                    <input
                      type="number"
                      min="0"
                      v-if="!variant.quickbooksId"
                      v-model="variant.stock"
                      :disabled="isBundle"
                      class="w-20 mx-2 styled text-center shadow"
                      @change="(altered = true), (product.quickbooksUpdate = true)"
                    />
                    <div v-else class="text-center w-20">
                      {{ variant.stock }}
                    </div>
                  </td>
                  <td v-if="fields.min">
                    <input
                      type="number"
                      min="0"
                      v-if="!variant.quickbooksId"
                      v-model="variant.min"
                      class="w-20 mx-2 styled text-center shadow"
                      @change="
                        (altered = true), (product.quickbooksUpdate = true), (bundleChanged = true)
                      "
                    />
                    <div v-else class="text-center w-20">
                      {{ variant.min }}
                    </div>
                  </td>
                  <td v-if="fields.msrp">
                    <input
                      type="number"
                      v-model="variant.msrp"
                      step="any"
                      class="shadow text-center w-20 styled mx-2"
                      @change="
                        setVariantPricesWrapper(variant, product.vendor);
                        setBundleDiscount(variantIndex, 'msrp');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierOne"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierOne');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierTwo"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierTwo');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierThree"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierThree');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierFour"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierFour');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierFive"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierFive');
                      "
                    />
                  </td>
                  <td v-if="fields.tiers">
                    <input
                      type="number"
                      min="price"
                      step="any"
                      v-model="variant.tierSix"
                      class="text-center w-20 mx-2 rounded-md border border-gray-300"
                      @change="
                        altered = true;
                        setBundleDiscount(variantIndex, 'tierSix');
                      "
                    />
                  </td>
                  <td v-if="fields.cost">
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-if="!variant.quickbooksId"
                      v-model="variant.cost"
                      class="text-center w-20 rounded-md border border-gray-300"
                      @change="
                        (altered = true), (costChanged = true), (product.quickbooksUpdate = true)
                      "
                      :disabled="isBundle"
                    />
                    <div v-else class="text-center w-20">${{ variant.cost }}</div>
                  </td>
                  <td v-if="fields.wholeSale">
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSaleQtyOne"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300"
                      placeholder="10"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.wholeSale" class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <span class="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSalePriceOne"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300 pl-4"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.wholeSale">
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSaleQtyTwo"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300"
                      placeholder="20"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.wholeSale" class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <span class="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSalePriceTwo"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300 pl-4"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.wholeSale">
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSaleQtyThree"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300"
                      placeholder="30"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.wholeSale" class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <span class="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      min="0"
                      step="any"
                      v-model="variant.wholeSalePriceThree"
                      class="text-left mx-2 w-20 rounded-md border border-gray-300 pl-4"
                      @change="altered = true"
                    />
                  </td>
                  <td v-if="fields.piece">
                    <input
                      type="text"
                      @change="altered = true"
                      v-model="variant.pieceSize"
                      class="w-32 rounded-md border border-gray-300"
                    />
                  </td>
                  <td v-if="fields.weight" class="flex my-3">
                    <div class="relative z-0">
                      <input
                        type="number"
                        @change="altered = true"
                        v-model="variant.lb"
                        class="w-12 rounded-l-md border border-r-0 border-gray-300"
                      />
                      <span class="absolute right-0 mr-3 text-xs mt-4">lb</span>
                    </div>
                    <div class="relative">
                      <input
                        type="number"
                        @change="altered = true"
                        v-model="variant.oz"
                        class="w-12 rounded-r-md border border-l-0 border-gray-300"
                      />
                      <span class="absolute right-0 mr-3 text-xs mt-4">oz</span>
                    </div>
                  </td>
                  <td v-if="fields.boxQty">
                    <input
                      type="number"
                      @change="altered = true"
                      v-model="variant.boxQty"
                      class="w-32 rounded-md border border-gray-300"
                    />
                  </td>
                  <td v-if="fields.barCode">
                    <input
                      type="text"
                      @change="altered = true"
                      v-model="variant.barCode"
                      class="w-32 rounded-md border border-gray-300"
                    />
                  </td>
                  <td v-if="fields.qb">
                    <div class="w-32 text-center mx-2 text-gray-500">
                      {{ variant.quickbooksId }}
                    </div>
                  </td>
                  <td v-if="fields.bin">
                    <input
                      type="text"
                      @change="altered = true"
                      v-model="variant.binLocation"
                      class="w-32 rounded-md border border-gray-300"
                    />
                  </td>
                  <td>
                    <ToggleButton
                      :width="toggleWidth"
                      :color="color"
                      :value="!variant.publish ? false : true"
                      :labels="{ checked: 'Yes', unchecked: 'No' }"
                      :margin="3"
                      @change="(variant.publish = !variant.publish), (altered = true)"
                    />
                  </td>
                  <td class="relative">
                    <font-awesome-icon
                      class="text-red mr-10 cursor-pointer"
                      :icon="['far', 'trash-alt']"
                      @click="deleteVariant(variantIndex, variant._id)"
                    />
                    <button class="absolute right-0 hover:bg-red-300 px-2 rounded">
                      <font-awesome-icon
                        icon="times"
                        @click="removeVariant(variantIndex, variant._id)"
                      />
                    </button>
                  </td>
                </tr>
                <!-- Products in Bundle -->
                <tr v-if="isBundle">
                  <td :colspan="23 + variant.options.length" class="text-center">
                    <table class="text-left table-fixed bg-white border my-3 mx-auto" width="99%">
                      <thead class="bg-blue-100">
                        <tr class="text-center">
                          <th class="text-primary-dark text-sm" width="20%">
                            <span class="my-2 d-block">Product</span>
                          </th>
                          <th class="text-primary-dark text-sm" width="20%">
                            <span class="my-2 d-block">Variant</span>
                          </th>
                          <th class="text-primary-dark text-sm z-1" width="15%">
                            <span class="my-2 d-block">Quantity</span>
                          </th>
                          <th class="text-primary-dark text-sm">MSRP</th>
                          <th class="text-primary-dark text-sm">Tier 1</th>
                          <th class="text-primary-dark text-sm">Tier 2</th>
                          <th class="text-primary-dark text-sm">Tier 3</th>
                          <th class="text-primary-dark text-sm">Tier 4</th>
                          <th class="text-primary-dark text-sm">Tier 5</th>
                          <th class="text-primary-dark text-sm">Tier 6</th>
                          <th class="text-primary-dark text-sm">Our Cost</th>
                          <th width="5%"></th>
                        </tr>
                      </thead>
                      <tbody class="h-1/2 overflow-y-auto">
                        <tr
                          v-for="(bundleItem, bundleIndex) in variant.bundleItems"
                          :key="variant._id + bundleItem._id + bundleIndex + variantIndex"
                          :class="bundleIndex % 2 ? 'bg-blue-100' : null"
                        >
                          <td>
                            <div
                              class="relative w-100 h-10"
                              :class="
                                productList[variantIndex][bundleIndex].showProducts ? 'z-1' : ''
                              "
                            >
                              <input
                                @focus="productList[variantIndex][bundleIndex].showProducts = true"
                                @keydown="
                                  productList[variantIndex][bundleIndex].searchRequest = true
                                "
                                @keyup="
                                  fetchProducts('productList', $event, variantIndex, bundleIndex)
                                "
                                class="rounded-md border border-gray-300 my-2 w-full"
                                type="text"
                                :value="productList[variantIndex][bundleIndex].searchValue"
                              />
                              <ul
                                class="bg-white border py-2 ml-2 w-full h-25 overflow-y-auto"
                                v-if="
                                  productList[variantIndex][bundleIndex].loading &&
                                  !productList[variantIndex][bundleIndex].products.length
                                "
                              >
                                <li class="cursor-pointer p-2">Loading ...</li>
                              </ul>
                              <ul
                                class="bg-white border py-2 ml-2 w-full h-48 overflow-y-auto"
                                v-if="
                                  productList[variantIndex][bundleIndex].searchValue.length &&
                                  productList[variantIndex][bundleIndex].showProducts &&
                                  !productList[variantIndex][bundleIndex].loading &&
                                  productList[variantIndex][bundleIndex].products.length
                                "
                              >
                                <li
                                  class="cursor-pointer p-2"
                                  v-bind:class="{
                                    'border-b-2':
                                      productIndex !==
                                      productList[variantIndex][bundleIndex].products.length - 1,
                                  }"
                                  v-for="(product, productIndex) in productList[variantIndex][
                                    bundleIndex
                                  ].products"
                                  :key="
                                    product._id +
                                    productIndex +
                                    variantIndex +
                                    bundleIndex +
                                    variant._id
                                  "
                                  v-html="
                                    boldenSuggestion(
                                      productList[variantIndex][bundleIndex].searchValue,
                                      product.title
                                    )
                                  "
                                  @click="selectProduct(product, variantIndex, bundleIndex)"
                                ></li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <select
                              class="ml-3 my-2 shadow w-100"
                              v-model="bundleItem.variant._id"
                              @change="
                                setBundleVariantData(bundleItem);
                                setVariantData(variantIndex);
                              "
                              required
                            >
                              <option
                                v-for="(variant, subVariantIndex) in bundleItem.variants"
                                v-bind:value="variant._id"
                                :key="variant._id + subVariantIndex + variantIndex + bundleIndex"
                                :hidden="
                                  alreadySelectedVariant(variant._id, bundleIndex, variantIndex)
                                "
                              >
                                {{ variant.productNum }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <input
                              :id="'bundle-quantity-' + bundleItem._id"
                              v-model="bundleItem.quantity"
                              class="rounded-md border border-gray-300 my-2"
                              type="number"
                              required
                              step="1"
                              min="1"
                              @change="setVariantData(variantIndex)"
                            />
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.msrp }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierOne }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierTwo }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierThree }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierFour }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierFive }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.tierSix }}</div>
                          </td>
                          <td>
                            <div class="text-center w-20">${{ bundleItem.variant.cost }}</div>
                          </td>
                          <td class="relative text-center flex items-center">
                            <font-awesome-icon
                              class="text-red mr-10 cursor-pointer"
                              :icon="['far', 'trash-alt']"
                              @click="removeBundleProduct(variantIndex, bundleItem._id)"
                            />
                            <div>
                              <router-link
                                tag="button"
                                :to="`/admin/products/${bundleItem.product}`"
                                class="ml-2 mt-2 hover:bg-blue-200 rounded"
                              >
                                <img src="/images/icons/Edit.svg" alt="Edit" width="15px" />
                              </router-link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="w-full">
                          <td colspan="3" class="p-4 text-primary">
                            <button
                              class="text-primary font-medium hover:bg-blue-200 px-3 py-2 rounded"
                              @click="addBundleProduct(variantIndex)"
                            >
                              <!-- <font-awesome-icon icon="plus" /> -->
                              + Add Product
                            </button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
              </tbody>
            </template>
            <tfoot>
              <tr class="w-full">
                <td colspan="3" class="py-4 text-primary">
                  <button
                    class="text-primary font-medium hover:bg-blue-200 px-3 py-2 rounded"
                    @click="addVariant()"
                  >
                    <!-- <font-awesome-icon icon="plus" /> -->
                    + Add Variant
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- End Variants -->

      <!-- Suggestions -->
      <div class="col-span-10 flex flex-col relative mx-10">
        <div class="flex items-center justify-between md:justify-start mx-6 mt-8">
          <h4 class="md:mr-12">Suggestions</h4>
        </div>
        <ProductCarousel
          :products="product.suggestions"
          @removeSuggestion="changeSuggestion"
          @addSuggestion="showSuggestionPopupMethod"
        />
      </div>

      <div class="w-full justify-end col-span-10 flex items-center">
        <div class="text-right">
          <div class="flex justify-end">
            <label for="sale" class="text-primary-dark font-semibold mr-2"> Sale </label>
            <div>
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.sale"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="(product.sale = !product.sale), (altered = true)"
              />
            </div>
          </div>

          <div class="flex justify-end">
            <label for="publish" class="text-primary-dark font-semibold mr-2">Publish</label>
            <div>
              <ToggleButton
                :color="color"
                :width="toggleWidth"
                :value="product.publish"
                :labels="{ checked: 'Yes', unchecked: 'No' }"
                :margin="3"
                @change="(product.publish = !product.publish), (altered = true)"
              />
            </div>
          </div>
          <div class="w-64 ml-auto my-4 z-10">
            <DualButton
              :options="dualButtonOptions"
              @execute="handleButtonMethods($event)"
              position="right"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 bg-white rounded shadow p-4">
      <div class="flex justify-between">
        <h4 class="text-primary">Logs</h4>
        <button @click="showLogs = !showLogs">
          <font-awesome-icon icon="chevron-down" :class="showLogs ? 'flip' : null" />
        </button>
      </div>
      <div v-if="showLogs">
        <Entry
          v-for="(log, index) in logs"
          :key="log._id"
          :entry="log"
          class="p-2"
          :class="index % 2 ? 'bg-gray-200' : null"
        />
      </div>
    </div>
    <!-- Fix this!!! -->
    <!-- <div class="flex w-full justify-end my-2">
      <navigation-controls
        @previous="previousProduct"
        @next="nextProduct"
        :previousId="previousProductId"
        :nextId="nextProductId"
      />
    </div> -->
    <!-- Product image uploader -->
    <AdminUploader
      :open="showProductUploader"
      @setValues="setProductImage"
      @uploadFinished="showProductUploader = false"
      @close="showProductUploader = false"
    ></AdminUploader>
    <!-- End Product Image Uploader -->

    <!-- Variant Image Uploader -->
    <AdminUploader
      :variant="true"
      @setValues="setVariantImage"
      @uploadFinished="showVariantUploader = false"
      @close="showVariantUploader = false"
      :open="showVariantUploader"
    ></AdminUploader>
    <!-- End Variant Image Uploader -->

    <!-- Tag -->
    <transition name="coverUpPopup">
      <PopUp v-if="showTagComponent" @close="showTagComponent = false" class="text-center">
        <Tag @closeModal="showTagComponent = false" :tag="tag" />
      </PopUp>
    </transition>
    <!-- End Tag -->

    <!-- Option Group -->
    <transition name="optionGroupPopup">
      <PopUp v-if="showOptionGroup" @close="showOptionGroup = false" class="text-center">
        <VariantGroup
          @closeModal="showOptionGroup = false"
          :variantOption="variantOption"
          @setValues="updateVariantOption"
        />
      </PopUp>
    </transition>
    <!-- End Option Group -->
    <!-- Option Group -->
    <transition name="suggestionPopup">
      <PopUp
        v-if="showSuggestionPopup"
        @close="showSuggestionPopupMethod"
        title="Add product to suggestions"
        class="text-center"
      >
        <div>
          <ProductSuggestionList
            @close="showSuggestionPopupMethod"
            @addSuggestion="changeSuggestion"
          ></ProductSuggestionList>
        </div>
      </PopUp>
    </transition>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';
import AdminUploader from '@/components/shared/AdminUploader';
import PopUp from '@/components/shared/DesktopModal';
import { ToggleButton } from 'vue-js-toggle-button';
import setVariantPrices from '@/assets/functions/pricing.js';
// import NavigationControls from '@/components/shared/NavigationControls.vue';
import DualButton from '@/components/shared/DualButton';
import ProductCarousel from '@/components/admin/products/ProductCarouselAdmin';
import ListAdd from '@/components/admin/shared/ListWAddItem.vue';
import Tag from './Tag.vue';
import VariantGroup from './VariantGroup.vue';
export const FETCH_TEXT_LENGTH_LIMIT = 2;
import ProductNotFound from './ProductNotFound.vue';
import FieldsTab from './FieldsTab.vue';
import { border_top as getBorderColor } from '@/assets/functions/getColor.js';
import Entry from './../logger/Entry.vue';
import ProductSuggestionList from './ProductSuggestionList.vue';

export default {
  data: () => {
    return {
      oldBundle: false,
      bundleChanged: false,
      variant: '',
      showFileOptions: false,
      showVariantOptions: false,
      showOptions: false,
      showRemoveOption: false,
      showRemoveVariant: false,
      toggleWidth: 75,
      color: '#005785',

      showProductUploader: false,
      showVariantUploader: false,
      currentVariant: null,

      showOptionGroup: false,
      optionIndex: -1,
      variantOption: null,

      showTagComponent: false,
      tag: null,

      productList: new Array(),
      relatedProductList: new Array(),
      tagControls: {
        selectionUp: [38],
        selectionDown: [40],
        select: [13],
        showList: [40],
        hideList: [27],
        autocomplete: [32, 13],
      },
      altered: false,
      costChanged: false,
      wholeSaleQty: 0,
      dualButtonOptions: [
        {
          name: 'Save',
          buttonMethod: 'save',
          text: 'Save the product.',
        },
        {
          name: 'Create Product Template',
          buttonMethod: 'create_template',
          text: 'Create a product template based on this product.',
        },
        {
          name: 'Sync product',
          buttonMethod: 'sync',
          text: 'Syncs this product to quickbooks.',
        },
        {
          name: 'Delete Product',
          buttonMethod: 'delete',
          text: 'This action is irreversable',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
        },
      ],
      fields: {},
      showLogs: false,
      showSuggestionPopup: false,
    };
  },
  computed: {
    alreadySelectedVariant() {
      return function (variant, bundleIndex, variantIndex) {
        return (
          this.product.variants[variantIndex].bundleItems.filter(
            (bundleItem, index) => index !== bundleIndex && bundleItem.variant._id === variant
          ).length > 0
        );
      };
    },
    loading: {
      get() {
        return this.$store.getters['admin/getLoading'];
      },
      set(val) {
        this.$store.commit('admin/setLoading', val);
      },
    },
    user() {
      return this.$store.state.user;
    },
    vendor: {
      get() {
        return this.product.vendor;
      },
      set(val) {
        this.product.vendor = val;
      },
    },
    product: {
      set(val) {
        this.$store.commit('products/setProduct', val);
      },
      get() {
        const product = this.$store.state.products.product;
        if (product && product.isBundle && !this.productList.length) {
          for (let variantIndex = 0; variantIndex < product.variants.length; variantIndex++) {
            const element = product.variants[variantIndex];
            for (let bundleIndex = 0; bundleIndex < element.bundleItems.length; bundleIndex++) {
              const bundleElement = element.bundleItems[bundleIndex];
              this.addToProductList(variantIndex, bundleIndex, bundleElement.productTitle, true);
            }
          }
        }
        if (product && product.relatedProduct && !this.relatedProductList.length) {
          for (let productIndex = 0; productIndex < product.relatedProduct.length; productIndex++) {
            const relatedProduct = product.relatedProduct[productIndex];
            this.addToRelatedProductList(productIndex, relatedProduct.productTitle, true);
          }
        }
        return product;
      },
    },
    isBundle: {
      set(isBundle) {
        if (!this.oldBundle && isBundle) {
          this.oldBundle = true;
        }
        this.product.isBundle = isBundle;
      },
      get() {
        return this.product && this.product.isBundle;
      },
    },
    vendors() {
      return this.$store.getters['tags/getTags']({ type: 'Vendor' }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    categories() {
      return this.$store.getters['tags/getTags']({ type: 'Category' }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    subcategories() {
      return this.$store.getters['tags/getTags']({ type: 'Subcategory' }).sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    tags() {
      return this.$store.getters['tags/getTags']({
        type: 'Tag',
      });
    },
    collections() {
      return this.$store.getters['tags/getTags']({
        type: 'Collection',
      });
    },
    previousProductId() {
      return this.$store.getters['admin/getPreviousProductId'];
    },
    nextProductId() {
      return this.$store.getters['admin/getNextProductId'];
    },
    logs() {
      return this.$store.getters.getLogs;
    },
  },
  watch: {
    async $route() {
      if (this.$route.name == 'EditProduct') {
        await this.fetchProduct();
        // await this.fetchProducts();
        if (this.isBundle) {
          for (let variantIndex = 0; variantIndex < this.product.variants.length; variantIndex++) {
            const variant = this.product.variants[variantIndex];
            for (let bundleIndex = 0; bundleIndex < variant.bundleItems.length; bundleIndex++) {
              this.setBundleItemVariants(variantIndex, bundleIndex);
            }
          }
        }
      }
    },
    product() {
      if (this.$route.params.id === 'new' && this.product._id) {
        this.$router.push({ name: 'Product Editor', params: { id: `${this.product._id}` } });
      } else {
        if (this.isBundle) {
          for (let variantIndex = 0; variantIndex < this.product.variants.length; variantIndex++) {
            const variant = this.product.variants[variantIndex];
            for (let bundleIndex = 0; bundleIndex < variant.bundleItems.length; bundleIndex++) {
              this.setBundleItemVariants(variantIndex, bundleIndex);
            }
          }
        }
      }
    },
  },
  methods: {
    changeSuggestion(suggestion) {
      console.info(suggestion);
      this.$store.dispatch('products/changeSuggestion', {
        productId: this.product._id,
        suggestionId: suggestion._id,
      });
      if (
        this.product.suggestions.some((el) => {
          return el._id == suggestion._id ? true : false;
        })
      ) {
        this.product.suggestions = this.product.suggestions.filter(
          (el) => el._id != suggestion._id
        );
      } else {
        this.product.suggestions.push(suggestion);
      }
    },
    getBorderColor: getBorderColor,
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'save':
          this.saveProduct();
          break;
        case 'create_template':
          this.createProductTemplate();
          break;
        case 'delete':
          this.deleteProduct();
          break;
        default:
          this.sendToQuickbooks();
          break;
      }
    },
    boldenSuggestion(query, title) {
      const searchIndex = title.search(new RegExp(query, 'ig'));
      const finalResult =
        searchIndex !== -1
          ? `${title.substr(0, searchIndex)}<b>${title.substr(
              searchIndex,
              query.length
            )}</b>${title.substr(searchIndex + query.length)}`
          : title;
      return finalResult;
    },
    async fetchProducts(productKey, event, mainIndex, subIndex = undefined) {
      let productList =
        this[productKey] && this[productKey][mainIndex] ? this[productKey][mainIndex] : [];
      if (subIndex !== undefined && productList.length > 0) {
        productList = productList[subIndex];
      }
      if (
        productList &&
        (!event ||
          (event.target.value.length >= FETCH_TEXT_LENGTH_LIMIT &&
            productList.searchValue !== event.target.value)) &&
        productList.searchRequest
      ) {
        productList.loading = true;
        if (event) {
          productList.searchValue = event.target.value;
        } else {
          productList.searchRequest = false;
        }
        this.$store.commit('admin/clearProducts');
        productList.products = [];

        const productLoaded = await this.$store.dispatch('admin/getProducts', {
          search: encodeURIComponent(productList.searchValue),
          isBundle: false,
          showLoader: false,
        });

        if (productLoaded) {
          productList.products = [...this.$store.getters['admin/getProducts']];
          productList.loading = false;

          if (event) {
            productList.showProducts = true;
            this.$forceUpdate();
          }
        }
      }
      return productList.products;
    },
    removeVariant(variantIndex, id) {
      this.product.variants = this.product.variants.filter((el) => {
        return el._id != id;
      });

      this.$store.dispatch('admin/removeVariant', { product_id: this.product._id, variant_id: id });
      this.setVariantData(variantIndex);
    },
    async fetchProduct() {
      await this.$store.dispatch('products/getProductAdmin', this.$route.params.id);
    },
    addVariant() {
      const variant = {
        msrp: 0,
        tierOne: 0,
        tierTwo: 0,
        tierThree: 0,
        tierFour: 0,
        tierFive: 0,
        tierSix: 0,
        cost: 0,
        stock: 0,
        min: 1,
        options: new Array(this.product.options.length),
        bundleDiscount: {
          msrp: 0,
          tierOne: 0,
          tierTwo: 0,
          tierThree: 0,
          tierFour: 0,
          tierFive: 0,
          tierSix: 0,
        },
        bundleItems: [],
      };

      this.product.variants.push(variant);
    },
    selectRelatedProduct(product, productIndex) {
      this.altered = true;
      this.relatedProductList[productIndex].searchRequest = false;
      this.product.relatedProduct[productIndex].product = product._id;
      this.product.relatedProduct[productIndex].productTitle = product.title;
      this.relatedProductList[productIndex].searchValue = product.title;
      this.relatedProductList[productIndex].showProducts = false;
      this.setRelatedProduct(productIndex);
      this.$forceUpdate();
    },
    selectProduct(product, variantIndex, bundleIndex) {
      this.bundleChanged = true;
      this.altered = true;
      this.productList[variantIndex][bundleIndex].searchRequest = false;
      this.product.variants[variantIndex].bundleItems[bundleIndex].product = product._id;
      this.product.variants[variantIndex].bundleItems[bundleIndex].productTitle = product.title;
      this.productList[variantIndex][bundleIndex].searchValue = product.title;
      this.productList[variantIndex][bundleIndex].showProducts = false;
      this.setBundleItemVariants(variantIndex, bundleIndex);
      if (this.product.variants[variantIndex].bundleItems[bundleIndex].variant._id) {
        const findVariant = this.product.variants[variantIndex].bundleItems[
          bundleIndex
        ].variants.filter(
          (obj) =>
            obj._id === this.product.variants[variantIndex].bundleItems[bundleIndex].variant._id
        );
        if (findVariant.length === 0) {
          this.product.variants[variantIndex].bundleItems[bundleIndex].variant = { _id: null };
          this.product.variants[variantIndex].bundleItems[bundleIndex].quantity = 1;
        }
      }
    },
    resetBundleItems() {
      this.bundleChanged = true;
      if (this.isBundle) {
        for (let index = 0; index < this.product.variants.length; index++) {
          const element = this.product.variants[index];
          element.bundleItems = element.bundleItems.map((bundleItem) =>
            this.getBundleItemObject(bundleItem._id)
          );
        }
      } else {
        for (let index = 0; index < this.product.variants.length; index++) {
          const element = this.product.variants[index];
          element.bundleItems = new Array();
        }
      }
    },
    resetRelatedProduct(productIndex) {
      this.product.relatedProduct[productIndex] = {
        product: null,
        productTitle: '',
      };
      this.relatedProductList[productIndex].searchValue = '';
      this.$forceUpdate();
    },
    setRelatedProduct(productIndex) {
      const product = this.relatedProductList[productIndex].products.filter(
        (prod) => prod._id === this.product.relatedProduct[productIndex].product
      );
      if (product.length) {
        this.product.relatedProduct[productIndex] = {
          product: product[0]._id,
          productTitle: product[0].title,
        };
      }
    },
    showSuggestionPopupMethod() {
      this.showSuggestionPopup = !this.showSuggestionPopup;
    },
    setBundleItemVariants(variantIndex, bundleIndex) {
      const product = this.productList[variantIndex][bundleIndex].products.filter(
        (prod) => prod._id === this.product.variants[variantIndex].bundleItems[bundleIndex].product
      );
      if (product.length) {
        this.product.variants[variantIndex].bundleItems[bundleIndex].variants = product[0].variants;
      }
    },
    removeBundleProduct(variantIndex, id) {
      this.product.variants[variantIndex].bundleItems = this.product.variants[
        variantIndex
      ].bundleItems.filter((el) => {
        return el._id != id;
      });

      if (!id.startsWith('new')) {
        this.$store.dispatch('admin/removeBundleItem', {
          variant_id: this.product.variants[variantIndex]._id,
          bundle_item_id: id,
        });
        this.bundleChanged = true;
        this.altered = true;
      }
      this.setVariantData(variantIndex);
    },
    addBundleProduct(variantIndex) {
      this.bundleChanged = true;
      this.altered = true;
      const lastIndex = this.product.variants[variantIndex].bundleItems.length || 0;
      this.product.variants[variantIndex].bundleItems.push(
        this.getBundleItemObject(`new-${lastIndex}`)
      );
      this.addToProductList(variantIndex, lastIndex);
    },
    getBundleItemObject(
      _id,
      productTitle = '',
      productNum = '',
      product = null,
      variant = { _id: null },
      quantity = 1,
      variants = new Array()
    ) {
      return {
        _id,
        productTitle,
        productNum,
        product,
        variant,
        quantity,
        variants,
      };
    },
    addToRelatedProductList(
      productIndex,
      searchValue = '',
      searchRequest = false,
      showProducts = false,
      loading = false,
      products = []
    ) {
      this.relatedProductList[productIndex] = {
        showProducts,
        searchRequest,
        loading,
        searchValue,
        products,
      };
    },
    addToProductList(
      variantIndex,
      bundleIndex,
      searchValue = '',
      searchRequest = false,
      showProducts = false,
      loading = false,
      products = []
    ) {
      if (!this.productList[variantIndex]) {
        this.productList[variantIndex] = new Array();
      }
      this.productList[variantIndex][bundleIndex] = {
        showProducts,
        searchRequest,
        loading,
        searchValue,
        products,
      };
    },
    setBundleVariantData(bundleItem) {
      const selectedVariant = bundleItem.variants.filter(
        (obj) => obj._id === bundleItem.variant._id
      );
      bundleItem.variant =
        selectedVariant.length > 0 ? { ...selectedVariant[0] } : bundleItem.variant;
    },
    setVariantData(variantIndex) {
      this.altered = true;
      if (
        this.product.variants.length > 0 &&
        this.product.variants[variantIndex].bundleItems.length > 0
      ) {
        const variant = this.product.variants[variantIndex];
        variant.msrp = 0;
        variant.tierOne = 0;
        variant.tierTwo = 0;
        variant.tierThree = 0;
        variant.tierFour = 0;
        variant.tierFive = 0;
        variant.tierSix = 0;
        variant.cost = 0;
        variant.stock = null;
        for (let index = 0; index < variant.bundleItems.length; index++) {
          const element = variant.bundleItems[index];
          const selectedVariant = element.variants.filter(
            (vari) => vari._id === element.variant._id
          );
          if (selectedVariant.length > 0) {
            element.productNum = selectedVariant[0].productNum;
            variant.msrp += element.quantity * selectedVariant[0].msrp;
            variant.tierOne += element.quantity * selectedVariant[0].tierOne;
            variant.tierTwo += element.quantity * selectedVariant[0].tierTwo;
            variant.tierThree += element.quantity * selectedVariant[0].tierThree;
            variant.tierFour += element.quantity * selectedVariant[0].tierFour;
            variant.tierFive += element.quantity * selectedVariant[0].tierFive;
            variant.tierSix += element.quantity * selectedVariant[0].tierSix;
            variant.cost += element.quantity * selectedVariant[0].cost;
            const availableStock = Math.floor(selectedVariant[0].stock / element.quantity);
            if (variant.stock === null || variant.stock > availableStock) {
              variant.stock = availableStock;
            }
          }
        }
        this.setBundleDiscount(variantIndex, 'msrp');
        this.setBundleDiscount(variantIndex, 'tierOne');
        this.setBundleDiscount(variantIndex, 'tierTwo');
        this.setBundleDiscount(variantIndex, 'tierThree');
        this.setBundleDiscount(variantIndex, 'tierFour');
        this.setBundleDiscount(variantIndex, 'tierFive');
        this.setBundleDiscount(variantIndex, 'tierSix');
      }
    },
    setBundleDiscount(variantIndex, field) {
      let originalValue = 0;
      if (this.isBundle && this.product.variants[variantIndex].bundleItems.length > 0) {
        const variant = this.product.variants[variantIndex];
        for (let index = 0; index < variant.bundleItems.length; index++) {
          const element = variant.bundleItems[index];
          const selectedVariant = element.variants.filter(
            (vari) => vari._id === element.variant._id
          );
          if (selectedVariant.length > 0) {
            originalValue += element.quantity * selectedVariant[0][field];
          }
        }
        if (!variant.bundleDiscount) {
          variant.bundleDiscount = {};
        }
        variant.bundleDiscount[field] = (
          100 -
          Math.round((variant[field] / originalValue) * 10000) / 100
        ).toFixed(2);
      }
    },
    async saveProduct() {
      if (this.product.variants.length === 0) {
        alert('Product must have at least one variant');
        return;
      }
      if (this.isBundle) {
        for (let variantIndex = 0; variantIndex < this.product.variants.length; variantIndex++) {
          const element = this.product.variants[variantIndex];
          const productEmpty = element.bundleItems.filter((bundle) => {
            return !!bundle.product === false;
          });
          if (productEmpty.length > 0) {
            alert('Please select bundle product');
            return;
          }
          const variantEmpty = element.bundleItems.filter((bundle) => {
            return !!bundle.variant._id === false;
          });
          if (variantEmpty.length > 0) {
            alert('Please select bundle product variant');
            return;
          }
          const quantityEmpty = element.bundleItems.filter((bundle) => {
            return !!bundle.quantity === false;
          });
          if (quantityEmpty.length > 0) {
            alert('Please select bundle product quantity');
            return;
          }
        }
      }
      if (this.costChanged) {
        if (
          !confirm(
            'You have changed the cost of a variant without changing the MSRP. Are you sure you would like to proceed to save?'
          )
        ) {
          return;
        }
      }

      this.product.variantProductNumbers = new Array();
      this.product.variants.forEach((el) => {
        el.product_id = this.product._id;
        el.product_name = this.product.title;
        this.product.variantProductNumbers.push(el.productNum);
        if (this.isBundle) {
          el.bundleItems = el.bundleItems.map((obj) => {
            return {
              ...obj,
              variant: obj.variant._id,
            };
          });
        }
      });

      if (this.product.description) {
        this.product.description = this.product.description.replace(/\n/g, '<br>');
      }

      if (this.product._id) {
        await this.$store.dispatch('admin/updateProduct', this.product);
      } else {
        this.$store.dispatch('admin/addProduct', this.product);
      }
      this.altered = false;
      await this.fetchProduct();
    },
    deleteProduct() {
      if (
        !confirm(
          `Are you sure you would like to delete ${this.product.title}? This is irreversable.`
        )
      ) {
        return;
      }
      this.$store.dispatch('admin/deleteProduct', this.product._id);
      this.altered = false;
      this.$router.push('/admin/products');
    },

    setProductImage(value) {
      this.product.image = value.thumbnail;
      this.altered = true;
    },

    //variants

    saveVariants() {
      this.$store.dispatch('admin/saveVariants', {
        product_id: this.product._id,
        variants: this.product.variants,
      });
      this.altered = false;
    },
    duplicateVariant(payload, variantIndex) {
      const variant = JSON.parse(JSON.stringify(payload));
      delete variant._id;
      delete variant.quickbooksId;
      if (this.isBundle) {
        this.duplicateProductList(variantIndex);
        variant.bundleItems = variant.bundleItems.map((bundle) => {
          return {
            ...bundle,
            _id: undefined,
          };
        });
      }
      this.product.variants.push(variant);
    },
    addOrUpdateGroup(variantIndex, optionIndex) {
      this.optionIndex = optionIndex;
      this.variantIndex = variantIndex;
      this.variantOption = this.product.variants[this.variantIndex].options[this.optionIndex];
      this.showOptionGroup = true;
    },
    updateVariantOption(options) {
      this.altered = true;
      this.product.variants[this.variantIndex].options[this.optionIndex] = options;
      this.showOptionGroup = false;
    },
    duplicateProductList(variantIndex) {
      this.productList.push(JSON.parse(JSON.stringify(this.productList[variantIndex])));
    },
    setVariantImage(value) {
      this.currentVariant.image = value.path;
      this.currentVariant.thumbnail = value.thumbnail;
      this.altered = true;
    },
    setVariantPricesWrapper(variant, vendor) {
      this.costChanged = false;
      this.altered = true;

      if (!this.isBundle) {
        setVariantPrices(variant, vendor);
      }
    },

    addOption() {
      if (this.product.options.length < 6) {
        this.product.options.push('');
        this.product.variants.forEach((el) => {
          if (el.options.length != this.product.options.length) {
            el.options.push('');
          }
        });
        this.showVariantOptions = false;
      } else {
        this.$store.commit('setError', {
          message: 'You cannot add more than 6 options.',
          color: 'red',
          duration: 6500,
        });
      }
    },

    changeOptionType(index) {
      if (typeof this.product.options[index] === 'string') {
        this.product.options[index] = {
          type: 'group',
          value: this.product.options[index],
        };
        this.product.variants.forEach((el) => {
          el.options[index] = [];
        });
      } else {
        this.product.options[index] = this.product.options[index].value;
        this.product.variants.forEach((el) => {
          el.options[index] = '';
        });
      }
      this.$forceUpdate();
    },

    isGroup(option) {
      return typeof option !== 'string' && option && option.type && option.type === 'group';
    },

    removeOption(index) {
      if (!confirm(`Are you sure you would like to remove option? This is irreversable.`)) {
        return;
      }
      this.product.options.splice(index, 1);
      this.product.variants.forEach((el) => {
        el.options.splice(index, 1);
      });
      this.showRemoveOption = false;
    },

    deleteVariant(variantIndex, variant_id) {
      if (!confirm(`Are you sure you would like to delete variant? This is irreversable.`)) {
        return;
      }
      this.$store.dispatch('admin/deleteVariant', {
        product_id: this.product._id,
        variant_id: variant_id,
      });

      this.product.variants = this.product.variants.filter((el) => {
        return el._id !== variant_id;
      });
      this.product.variants.forEach((el) => {
        this.product.variantProductNumbers.push(el.productNum);
      });
      this.altered = false;
    },
    addToCategories(value) {
      this.product.categories.push(value);
    },
    removeFromCategories(name) {
      if (!confirm(`Are you sure you would like to remove category?`)) {
        return;
      }
      this.product.categories = this.product.categories.filter((el) => {
        return el !== name;
      });
    },
    addToSubcategories(value) {
      this.product.subcategories.push(value);
    },
    removeFromSubcategories(name) {
      if (!confirm(`Are you sure you would like to remove subcategory?`)) {
        return;
      }
      this.product.subcategories = this.product.subcategories.filter((el) => {
        return el !== name;
      });
    },
    removeTag(tag) {
      if (!confirm(`Are you sure you would like to remove tag?`)) {
        return;
      }
      this.product.tags = this.product.tags.filter((el) => {
        return el != tag;
      });
    },
    addTag(tag) {
      if (this.product.tags.includes(tag)) {
        return;
      }
      this.product.tags.push(tag);
      this.altered = true;
    },
    addToCollections(tag) {
      if (this.product.collections.includes(tag)) {
        return;
      }
      this.product.collections.push(tag);
      this.altered = true;
    },
    removeFromCollection(tag) {
      if (!confirm(`Are you sure you would like to remove collection?`)) {
        return;
      }
      this.product.collections = this.product.collections.filter((el) => {
        return el != tag;
      });
    },
    setWholeSaleQty() {
      const qty = this.wholeSaleQty;
      const num = document.getElementById('wholeSaleNum').value * 1;
      this.product.variants.forEach(function (el) {
        switch (num) {
          case 1:
            el.wholeSaleQtyOne = qty;
            break;
          case 2:
            el.wholeSaleQtyTwo = qty;
            break;
          default:
            el.wholeSaleQtyThree = qty;
        }
      });
      this.$forceUpdate();
    },
    async nextProduct() {
      if (this.nextProductId) {
        this.$router.push({ name: 'Product Editor', params: { id: this.nextProductId } });
        await this.fetchProduct();
      }
    },
    async previousProduct() {
      if (this.previousProductId) {
        this.$router.push({ name: 'Product Editor', params: { id: this.previousProductId } });
        await this.fetchProduct();
      }
    },
    createProductTemplate() {
      const name = window.prompt('What would you like the name to be?');
      const templateEdit = {
        name,
        options: this.product.options,
        vendor: this.product.vendor,
        type: this.product.type,
        categories: this.product.categories,
        subcategories: this.product.subcategories,
        tags: this.product.tags,
      };

      let error = null;
      if (!name) {
        error = 'You must provide a name';
      }
      if (!templateEdit || !templateEdit.options || templateEdit.options.length == 0) {
        error = 'You must have at least 1 option.';
      }

      if (error) {
        this.$store.commit('setError', {
          message: error,
          color: 'red',
          duration: 6500,
        });
        return;
      }

      this.$store.dispatch('productTemplates/create', templateEdit);
    },
    setTagEdit(payload) {
      this.tag = {};
      this.tag.name = payload.name;
      switch (payload.type) {
        case 'Subcategories':
          this.tag.type = 'Subcategory';
          break;
        case 'Categories':
          this.tag.type = 'Category';
          break;
        case 'Tags':
          this.tag.type = 'Tag';
          break;
        case 'Collection':
          this.tag.type = 'Collection';
          break;
      }

      const tags = this.$store.getters['tags/getTags']({ type: this.tag.type });

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].name === payload.name) {
          this.tag = tags[i];
        }
      }
      this.showTagComponent = true;
    },
    refreshPage(data) {
      console.info('refreshPage', data);
      this.fields = data;
    },
    sendToQuickbooks() {
      this.$store.dispatch('quickbooks/syncIndividualProduct', [this.product._id]);
    },
    setLocalStorage() {
      window.localStorage.setItem('product-fields', JSON.stringify(this.fields));
    },
    getLocalStorage() {
      const fields = JSON.parse(window.localStorage.getItem('product-fields'));
      if (fields) {
        this.fields = fields;
      }
    },
  },
  components: {
    Loading,
    ToggleButton,
    AdminUploader,
    PopUp,
    // NavigationControls,
    DualButton,
    ProductCarousel,
    ListAdd,
    Tag,
    VariantGroup,
    ProductNotFound,
    FieldsTab,
    Entry,
    ProductSuggestionList,
  },
  async mounted() {
    await this.fetchProduct();
    if (this.isBundle) {
      for (let variantIndex = 0; variantIndex < this.product.variants.length; variantIndex++) {
        const element = this.product.variants[variantIndex];
        for (let bundleIndex = 0; bundleIndex < element.bundleItems.length; bundleIndex++) {
          await this.fetchProducts('productList', null, variantIndex, bundleIndex);
          this.setBundleItemVariants(variantIndex, bundleIndex);
        }
      }
    }
    // this.setVariantData(variantIndex);
    this.$forceUpdate();
    // await this.fetchProducts();
    this.getLocalStorage();
  },
  beforeRouteLeave(to, from, next) {
    if (this.altered) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};

// eslint-disable-next-line no-unused-vars
document.addEventListener('wheel', function (event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul.withIcons {
  min-width: 8rem;
}
.d-block {
  display: block;
}
</style>

<style>
.suggest-item:hover {
  background-color: #97c8e1;
}

.suggestions {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 0 5px;
}

ul {
  list-style-position: inside;
}
#tags {
  outline: 0;
}
.default-input {
  width: 100%;
}
.tableFixHead {
  overflow-y: auto;
  max-height: 50vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}
.bundle-table thead thead th {
  position: relative;
  top: auto;
}
table {
  border-collapse: collapse;
}
.w-100 {
  width: 90%;
}
.related-product .remove {
  position: absolute;
  right: 5px;
  color: #a0aebf;
  top: 50%;
  margin-top: -5px;
  line-height: 1;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pr-50 {
  padding-right: 50px !important;
}
</style>
