<template>
  <div
    class="fixed right-0 top-0 z-10 pt-20 shadow-md bg-gray-200 h-screen p-3 overflow-y-auto"
    id="quick-edit"
  >
    <div
      class="w-2 bg-blue absolute left-0 h-full z-10 top-0"
      draggable="true"
      @dragend="resize($event)"
      style="cursor: col-resize"
    ></div>
    <div
      class="right-3 top-5 absolute hover:bg-gray-300 px-3 py-1 text-xl cursor-pointer rounded"
      @click="$emit('minimize')"
    >
      <font-awesome-icon icon="minus" />
    </div>
    <div
      class="right-1 top-5 absolute hover:bg-red-300 px-3 py-1 text-xl cursor-pointer rounded"
      @click="cancel"
    >
      <font-awesome-icon icon="times" />
    </div>
    <h3 class="p-2 mb-3">Price Edit</h3>
    <div v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <div class="ml-auto pl-3 relative w-48">
        <PercentagePriceChange
          class="ml-auto"
          title="Change all prices"
          name="selected "
          @alterData="changePrices"
        />
      </div>
      <div
        v-for="product in products"
        :key="product._id"
        class="py-8 mx-2 border-t border-gray-400"
      >
        <Product :product="product" />
      </div>
      <div class="flex place-items-end mt-4 mb-12">
        <DualButton
          :options="options"
          verticalPosition="top"
          @execute="handleButtonMethods($event)"
        />
      </div>
      <Emailer @overWriteAndSendEmail="overWriteAndSendEmail" />
    </div>
  </div>
</template>

<script>
import DualButton from '@/components/shared/DualButton';
import Product from './PriceEditProduct';
import PercentagePriceChange from '@/components/admin/shared/PercentagePriceChange';
import Emailer from '@/components/admin/vendors/Emailer';
import Loading from '@/components/shared/Loading';

export default {
  data: function () {
    return {
      end: null,
      width: 500,
      altered: false,
      costChanged: false,
      showEmailer: false,
      options: [
        {
          name: 'Save All',
          text: 'Save all of the selected products and close',
          buttonMethod: 'save',
        },
        {
          name: 'Update Special Pricing',
          text:
            'Save all of the selected products. If the cost of a product was increased, the percentage difference between the new cost and the old cost will be applied to all users who have the altered item.',
          buttonMethod: 'overwrite',
        },
        {
          name: 'Update Special Pricing and Send Email',
          text:
            "Save all of the selected products. If the cost of a product was increased, the percentage difference between the new cost and the old cost will be applied to all users who have the altered item. If a user's special priced item changes in price, an email will be sent to the user with a list of the products which have a price change.",
          buttonMethod: 'open_email',
        },
        {
          name: 'Close',
          text: 'Deselects all of the selected products and closes the price editor.',
          buttonMethod: 'cancel',
        },
      ],
    };
  },
  props: {
    products: Array,
  },
  computed: {
    loading() {
      return this.$store.getters['variants/getLoading'];
    },
  },
  components: {
    DualButton,
    Product,
    PercentagePriceChange,
    Emailer,
    Loading,
  },
  watch: {},
  mounted() {},
  methods: {
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'save':
          this.save();
          break;
        case 'overwrite':
          this.overWrite();
          break;
        case 'open_email':
          this.openEmailer();
          break;
        default:
          this.cancel();
          break;
      }
    },
    openEmailer() {
      this.$store.commit('tags/setPriceChangeEmailer', true);
    },
    overWriteAndSendEmail(users) {
      let variants = [];

      this.products.forEach((el) => {
        variants = variants.concat(el.variants);
      });

      this.$store.dispatch('company/overWriteVariants', {
        sendEmail: true,
        variants,
        users,
      });
    },
    changePrices({ percentage }) {
      this.products.forEach((product) => {
        product.variants.forEach((variant) => {
          variant.msrp *= percentage;
          variant.tierOne *= percentage;
          variant.tierTwo *= percentage;
          variant.tierThree *= percentage;
          variant.tierFour *= percentage;
          variant.tierFive *= percentage;
          variant.tierSix *= percentage;
          variant.cost *= percentage;
        });
      });
    },
    overWrite() {
      let variants = [];

      this.products.forEach((el) => {
        variants = variants.concat(el.variants);
      });
      this.$store.dispatch('company/overWriteVariants', {
        sendEmail: false,
        variants,
      });
    },
    save() {
      if (this.costChanged) {
        if (
          !confirm(
            'You have changed the cost of a variant without changing the MSRP. Are you sure you would like to proceed to save?'
          )
        ) {
          return;
        }
      }

      this.altered = false;
      this.$store.dispatch('admin/updateProducts', this.products);
    },
    cancel() {
      if (this.altered) {
        if (
          confirm(
            'If you close this page, your saves will not be saved. Are you sure you would like to cancel'
          )
        ) {
          this.altered = false;
          this.$emit('cancel');
        }
      } else {
        this.altered = false;
        this.$emit('cancel');
      }
    },
    resize(e) {
      const wrapper = document.getElementById('quick-edit');
      wrapper.style.width = e.view.innerWidth - e.screenX + 'px';
    },
    toProductPage(id) {
      if (this.altered) {
        if (
          confirm(
            'If you leave this page, your saves will not be saved. Are you sure you would like to continue?'
          )
        ) {
          this.$router.push({ path: `/admin/products/${id}` });
        }
      } else {
        this.$router.push({ path: `/admin/products/${id}` });
      }
    },
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#quick-edit {
  width: 950px;
}
</style>
