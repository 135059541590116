<template>
  <div class="relative">
    <button
      @click="scrollLeft()"
      class="hidden md:block absolute focus:outline-none -left-4 top-7 px-6 py-8 rounded-sm"
    >
      <font-awesome-icon icon="chevron-left" class="text-2xl text-gray-700" />
    </button>
    <button
      @click="scrollRight()"
      class="hidden md:block absolute focus:outline-none -right-4 top-7 px-6 py-8 rounded-sm hover:bg-gray-300"
    >
      <font-awesome-icon icon="chevron-right" class="text-2xl text-gray-700" />
    </button>
    <div class="">
      <transition-group
        name="product"
        tag="div"
        ref="scrollWrapper"
        class="carousel z-1 scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray scrolling-touch"
      >
        <ProductTile
          v-for="(product, index) in products"
          :key="`${index}-product`"
          :productInfo="product"
          :index="index"
          :length="products.length"
          @removeSuggestion="removeSuggestion"
        />
        <div
          class="relative w-64 h-64 shadow-md ml-3 overflow-hidden my-5 py-20 rounded-xl bg-white flex flex-col text-center justify-between"
          :key="`${products.length}-product`"
        >
          <div class="text-lg font-bold mb-6">Add Suggestion</div>
          <button
            class="w-16 h-16 rounded-full shadow-md bg-blue-glow-light mx-auto hover:bg-blue-glow text-white text-3xl flex items-center justify-center cursor-pointer"
            @click="$emit('addSuggestion')"
          >
            <font-awesome-icon icon="plus" />
          </button>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import ProductTile from '@/components/admin/products/ProductTileAdmin';
export default {
  components: {
    ProductTile,
  },
  props: {
    products: Array,
  },
  methods: {
    removeSuggestion(val) {
      this.$emit('removeSuggestion', val);
    },
    scrollLeft() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: -900,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.scrollWrapper.$el.scrollBy({
        top: 0,
        left: 900,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style>
.carousel {
  overflow: auto;
  white-space: nowrap;
  /* scroll-snap-type: x proximity; */
}

/* For anything larger than mobile device */
@media only screen and (min-width: 600px) {
  .carousel-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    width: 15px;
  }

  .carousel-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 15px;
  }
}

.carousel > * {
  display: inline-block;
}
</style>
