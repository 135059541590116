<template>
  <div class="h-1/2-screen overflow-y-auto">
    <!-- Search -->
    <div class="flex relative justify-end">
      <form @submit.prevent="clearProducts()" class="relative w-80">
        <button type="submit" class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1">
          <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
        </button>

        <input
          class="bg-white w-full rounded pl-12 py-2 outline-none"
          v-model="searchValue"
          @keydown.esc="(searchValue = null), clearProducts()"
          placeholder="Name or Product Number"
          type="search"
          autocomplete="off"
        />
      </form>
    </div>
    <div class="grid grid-cols-8 py-2 bg-white rounded-t mt-2">
      <div class=""></div>
      <div class="col-span-3">Title</div>
      <div class="">Vendor</div>
      <div class="">Type</div>
      <div class="">Purchases</div>
      <div></div>
    </div>
    <div
      v-for="(product, index) in products"
      :key="product._id"
      class="grid grid-cols-8 py-2"
      :class="index % 2 ? 'bg-gray-100' : null"
    >
      <div class="rounded overflow-hidden shadow mx-auto w-12 flex items-center justify-center">
        <img
          v-if="product.image"
          v-lazy="product.image"
          :alt="`Image of ${product.title}`"
          class="w-10"
        />
        <img
          v-else-if="product.variants[0]"
          v-lazy="product.variants[0].image"
          :alt="`Image of ${product.title}'s variant`"
          class="w-10"
        />
      </div>

      <div class="overflow-hidden whitespace-no-wrap col-span-3 my-auto font-medium">
        {{ product.title }}
      </div>
      <div class="overflow-x-hidden whitespace-no-wrap my-auto relative">
        {{ product.vendor }}
      </div>
      <div class="overflow-hidden my-auto">
        {{ product.type }}
      </div>
      <div class="overflow-hidden my-auto">
        {{ product.purchased }}
      </div>
      <div>
        <button
          class="w-12 h-12 rounded-full shadow-md bg-blue-glow-light mx-auto hover:bg-blue-glow text-white text-xl flex items-center justify-center cursor-pointer transfor rotate-90"
          @click="$emit('addSuggestion', product)"
        >
          <font-awesome-icon icon="plus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      searchValue: null,
    };
  },
  computed: {
    products: {
      get() {
        return this.$store.getters['admin/getProducts'];
      },
    },
  },
  methods: {
    fetchProducts() {
      this.$store.dispatch('admin/getProducts', { search: this.searchValue, showLoader: false });
    },
    clearProducts() {
      this.$store.commit('admin/clearProducts');
      this.fetchProducts();
    },
  },
};
</script>

<style scoped>
.flip {
  transform: rotate(180deg);
}
</style>
