<template>
  <div>
    <div class="flex">
      <div
        @click="toProductPage(product._id)"
        class="w-20 h-20 rounded shadow mr-2 flex items-center cursor-pointer bg-white hover:bg-gray-100"
      >
        <img v-if="product.image" :src="product.image" alt="" />
        <img v-else :src="product.variants[0].image" alt="" />
      </div>

      <div class="w-full">
        <div class="flex items-center">
          <h4
            class="text-primary hover:text-primary-dark cursor-pointer mb-1 mr-auto"
            @click="toProductPage(product._id)"
          >
            {{ product.title }}
          </h4>
          <PercentagePriceChange @alterData="changePricing" :name="product.title" title="" />
        </div>

        <textarea
          v-model="product.description"
          placeholder="Product Description"
          class="w-full rounded"
        ></textarea>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-2 w-full font-semibold text-xs text-gray-700">
      <div class="col-span-2">Part #</div>
      <div>MSRP</div>
      <div>Tier 1</div>
      <div>Tier 2</div>
      <div>Tier 3</div>
      <div>Tier 4</div>
      <div>Tier 5</div>
      <div>Tier 6</div>
      <div>Cost</div>
      <div></div>
    </div>
    <div
      v-for="(variant, index) in product.variants"
      :key="variant._id"
      class="w-full"
      :class="index % 2 ? 'bg-blue-100' : null"
    >
      <Variant :variant="variant" :vendor="product.vendor" />
    </div>
  </div>
</template>

<script>
import Variant from './PriceEditVariant';
import PercentagePriceChange from '@/components/admin/shared/PercentagePriceChange';
export default {
  data: function () {
    return {};
  },
  props: { product: { type: Object, required: true } },
  computed: {},
  watch: {},
  methods: {
    toProductPage(id) {
      if (this.altered) {
        if (
          confirm(
            'If you leave this page, your saves will not be saved. Are you sure you would like to continue?'
          )
        ) {
          this.$router.push({ path: `/admin/products/${id}` });
        }
      } else {
        this.$router.push({ path: `/admin/products/${id}` });
      }
    },
    save() {},
    changePricing({ percentage }) {
      this.product.variants.forEach((variant) => {
        variant.msrp *= percentage;
        variant.tierOne *= percentage;
        variant.tierTwo *= percentage;
        variant.tierThree *= percentage;
        variant.tierFour *= percentage;
        variant.tierFive *= percentage;
        variant.tierSix *= percentage;
        variant.cost *= percentage;
      });
    },
  },
  mounted() {},
  components: {
    Variant,
    PercentagePriceChange,
  },
};
</script>
<style scoped></style>
