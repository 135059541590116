<template>
  <div v-if="loading">
    <Loading />
  </div>
  <div v-else class="h-1/2-screen overflow-y-auto px-2">
    <h3>Please choose a template</h3>
    <div>
      <label for="template" class="block text-sm font-medium text-gray-700">Template</label>
      <select
        id="template"
        name="template"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md"
        v-model="template"
      >
        <option v-for="template in templates" :key="template._id" :value="template">
          {{ template.name }}
        </option>
      </select>
    </div>
    <div v-if="template" class="grid grid-cols-2 gap-4 my-4">
      <div>
        <dt class="block text-sm font-medium text-gray-700">Name</dt>
        <dd class="">{{ template.name }}</dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Vendor</dt>
        <dd class="">{{ template.vendor }}</dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Type</dt>
        <dd class="">{{ template.type }}</dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Options</dt>
        <dd class="">
          <span v-for="(val, index) in template.options" :key="val">
            {{ val }}<span v-if="index < template.options.length - 1">,</span>
          </span>
        </dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Categories</dt>
        <dd class="">
          <span v-for="(val, index) in template.categories" :key="val">
            {{ val }}<span v-if="index < template.categories.length - 1">,</span>
          </span>
        </dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Subcategories</dt>
        <dd class="">
          <span v-for="(val, index) in template.subcategories" :key="val">
            {{ val }}<span v-if="index != template.subcategories.length - 1">,</span>
          </span>
        </dd>
      </div>
      <div>
        <dt class="block text-sm font-medium text-gray-700">Tags</dt>
        <dd class="">
          <span v-for="(val, index) in template.tags" :key="val">
            {{ val }}<span v-if="index != template.tags.length - 1">,</span>
          </span>
        </dd>
      </div>
    </div>
    <div class="flex items-end align mt-4 text-right w-full" v-if="template">
      <button @click="createNewProduct" class="btn-primary rounded">Confirm</button>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';
export default {
  data: () => {
    return {
      search: null,
      template: null,
    };
  },
  computed: {
    templates() {
      return this.$store.getters['productTemplates/getTemplates'](this.search);
    },
    loading() {
      return this.$store.getters['productTemplates/getLoading'];
    },
  },
  methods: {
    async createNewProduct() {
      if (this.template) {
        const val = { ...this.template };
        delete val._id;
        const response = await this.$store.dispatch('products/createNewProduct', val);
        this.$router.push('/admin/products/' + response.data._id);
      }
    },
  },
  mounted() {
    this.$store.dispatch('productTemplates/get');
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
