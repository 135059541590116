<template>
  <div class="relative border rounded p-2 bg-white ml-auto w-36">
    <div class="flex justify-between">
      <label> Fields </label>
      <button @click="toggleDisplay()">
        <font-awesome-icon
          icon="chevron-down"
          class="transition-transform duration-200"
          :class="showFields ? 'flip' : null"
        />
      </button>
    </div>
    <div
      class="absolute ml-05 right-0 top-2 z-10 shadow-lg bg-white border rounded py-2 px-1 w-48"
      v-if="showFields"
    >
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.stock" id="field_date" />
        </div>
        <label for="field_date" class="flex"> On hand </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.min" id="field_delivery" />
        </div>
        <label for="field_delivery" class="flex"> Minimum Quantity </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.msrp" id="field_total" />
        </div>
        <label for="field_total" class="flex"> MSRP </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.tiers" id="field_status" />
        </div>
        <label for="field_status" class="flex"> Tiers </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.cost" id="field_method" />
        </div>
        <label for="field_method" class="flex"> Cost</label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input
            type="checkbox"
            class="styled border"
            v-model="fields.wholeSale"
            id="field_purchase_order"
          />
        </div>
        <label for="field_purchase_order" class="flex"> Whole Sale </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.piece" id="field_job_name" />
        </div>
        <label for="field_job_name" class="flex"> Piece Size </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.weight" id="printed" />
        </div>
        <label for="printed" class="flex"> Weight </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.boxQty" id="field_box_qty" />
        </div>
        <label for="field_box_qty" class="flex">Box Quantity </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input
            type="checkbox"
            class="styled border"
            v-model="fields.barCode"
            id="field_bar_code"
          />
        </div>
        <label for="field_bar_code" class="flex">Bar Code </label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.qb" id="field_quick_books" />
        </div>
        <label for="field_quick_books" class="flex">Quickbooks ID</label>
      </div>
      <div class="flex">
        <div class="w-4 h-4 mx-2">
          <input type="checkbox" class="styled border" v-model="fields.bin" id="field_bin" />
        </div>
        <label for="field_bin" class="flex">Bin Location </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Object,
  },
  data() {
    return {
      showFields: false,
    };
  },
  methods: {
    toggleDisplay() {
      this.$emit('setLocalStorage');
      this.showFields = !this.showFields;
    },
  },
};
</script>

<style></style>
