<template>
  <div>
    <h3 class="text-primary mb-6">Add / Update Group Values</h3>
    <div class="w-full justify-center text-left">
      <div class="bg-white rounded relative w-full overflow-hidden">
        <div class="px-2 tableFixHead w-full">
          <table class="variant-options table-fixed bg-white my-2 w-full">
            <thead>
              <tr class="text-center">
                <th width="10%" class="text-primary-dark text-sm bg-white z-1"></th>
                <th width="30%" class="z-1 bg-white">Group Name</th>
                <th width="50%" class="z-1 bg-white">Group Values</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody class="h-1/2 overflow-y-auto border">
              <tr v-for="(option, optionIndex) in options" :key="optionIndex">
                <td class="text-center">{{ optionIndex + 1 }}</td>
                <td>
                  <input
                    type="text"
                    v-model="options[optionIndex].groupName"
                    @change="altered = true"
                    class="styled w-full shadow mx-2"
                  />
                </td>
                <td>
                  <table class="text-left table-fixed bg-white my-3 mx-auto w-100">
                    <tr
                      v-for="(optionValue, optionValueIndex) in option.groupValues"
                      :key="optionValueIndex"
                    >
                      <td class="text-center" width="10%">
                        <label for="">{{ optionValueIndex + 1 }}</label>
                      </td>
                      <td width="60%">
                        <input
                          type="text"
                          :id="`group-value-${optionIndex}-${optionValueIndex}`"
                          v-model="options[optionIndex].groupValues[optionValueIndex]"
                          class="styled w-52 shadow my-2"
                        />
                      </td>
                      <td width="30%">
                        <button
                          class="bg-blue-glow-light hover:bg-blue-glow rounded-full w-8 h-8 text-white ml-2 p-1"
                          @click="addGroupValue(optionIndex)"
                        >
                          <font-awesome-icon icon="plus" />
                        </button>
                        <button
                          class="bg-red-glow-light hover:bg-red-glow rounded-full w-8 h-8 text-white ml-2 p-1"
                          v-if="option.groupValues.length !== 1"
                          @click="removeGroupValue(optionIndex, optionValueIndex)"
                        >
                          <font-awesome-icon icon="times" />
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <button
                    class="bg-blue-glow-light hover:bg-blue-glow rounded-full w-8 h-8 text-white ml-2 p-1"
                    @click="addGroup()"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                  <button
                    class="bg-red-glow-light hover:bg-red-glow rounded-full w-8 h-8 text-white ml-2 p-1"
                    v-if="options.length !== 1"
                    @click="removeGroup(optionIndex)"
                  >
                    <font-awesome-icon icon="times" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center md:text-right mt-4">
      <button type="submit" @click="saveOptions()" class="btn-action rounded text-lg">
        Save Groups
      </button>
      <button @click="close()" class="btn-white rounded text-lg ml-4">Cancel</button>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      options: new Array(),
    };
  },
  props: {
    variantOption: Array,
  },
  watch: {},
  mounted() {
    if (this.variantOption && this.variantOption.length > 0) {
      this.options = this.variantOption;
      return;
    }
    this.addGroup();
  },
  methods: {
    addGroup() {
      this.options.push({
        groupName: '',
        groupValues: [],
      });
      this.addGroupValue(this.options.length - 1);
    },
    removeGroup(optionIndex) {
      this.options.splice(optionIndex, 1);
    },
    addGroupValue(optionIndex) {
      this.options[optionIndex].groupValues.push('');
    },
    removeGroupValue(optionIndex, optionValueIndex) {
      this.options[optionIndex].groupValues.splice(optionValueIndex, 1);
    },
    saveOptions() {
      let filledValues = true;
      for (let i = 0; i < this.options.length; i += 1) {
        const option = this.options[i];
        if (option.groupName === '') {
          filledValues = false;
          i = this.options.length;
          return;
        }
        for (let j = 0; j < option.groupValues.length; j += 1) {
          const value = option.groupValues[j];
          if (value === '' || value === null) {
            filledValues = false;
            j = option.groupValues.length;
            i = this.options.length;
          }
        }
      }
      if (!filledValues) {
        this.$store.commit('setError', {
          message: 'Please fill all the values',
          color: 'red',
        });
        return;
      }

      this.$emit('setValues', this.options);
      this.close();
    },

    close() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style scoped></style>
