<template>
  <div class="pb-6">
    <div class="md:mx-auto relative">
      <!-- Show QuickEditor if it is minimized -->
      <button
        v-show="QEMinimized"
        :key="minKey"
        class="absolute right-1 px-3 py-1 top-0 mx-2 bg-white rounded shadow hover:bg-primary hover:text-white cursor-pointer"
        @click="QEMinimized = false"
      >
        Show Quick Editor
      </button>
      <!-- End -->

      <div class="flex flex-col sm:flex-row items-center mb-2 md:mb-0">
        <!-- Search -->
        <div class="flex relative">
          <form @submit.prevent="clearProducts()" class="relative w-80">
            <button type="submit" class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1">
              <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
            </button>

            <input
              class="bg-white w-full rounded-full pl-12 py-2 outline-none"
              v-model="searchValue"
              @keydown.esc="(searchValue = null), clearProducts()"
              placeholder="Name or Product Number"
              type="search"
              autocomplete="off"
            />
          </form>
        </div>
        <!-- End Search -->

        <Importer
          :selectedVendor="vendor === 'All Vendors' ? '' : vendor"
          :selectedProducts="selectedProducts"
          :category="category !== 'All Categories' ? category : null"
          :subCategory="subcategory !== 'All Subcategories' ? subcategory : null"
          class="mx-2 hidden md:block"
        />
        <div>
          <DualButton
            class="mr-2"
            :options="alterProductsButtonOptions"
            @execute="handleButtonMethods($event)"
          />
        </div>
        <Quickbooks :items="selectedProducts" page="Products" @setLoading="setLoading" />
      </div>

      <div class="bg-white shadow rounded relative">
        <!-- Legend -->
        <div class="absolute right-0 top-0 mt-2 mr-2">
          <div class="z-1 cursor-pointer text-primary hover:text-primary-light tooltip">
            <font-awesome-icon class="text-lg" icon="info-circle" />
            <span
              class="tooltiptext shadow text-black mx-1 flex flex-col w-64 text-left right-0 -top-2"
            >
              <span class="mx-2">
                <font-awesome-icon icon="exclamation-triangle" class="text-red mr-3" />Not in
                quickbooks
              </span>
            </span>
          </div>
        </div>
        <!-- End Legend -->

        <!-- Filter By -->
        <div class="my-4 p-4 hidden md:flex justify-between gap-x-4 items-center">
          <div class="w-1/2 text-center">Filter By:</div>
          <select
            id="vendors"
            v-model="vendor"
            @change="clearProducts"
            class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
          >
            <option v-for="vendor in vendors" :key="vendor._id">{{ vendor.name }}</option>
          </select>

          <select
            id="vendors"
            v-model="category"
            @change="clearProducts"
            class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
          >
            <option v-for="category in categories" :key="category._id">{{ category.name }}</option>
          </select>

          <select
            id="vendors"
            v-model="subcategory"
            @change="clearProducts"
            class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
          >
            <option v-for="subcategory in subcategories" :key="subcategory._id">
              {{ subcategory.name }}
            </option>
          </select>

          <select
            id="vendors"
            v-model="tag"
            @change="clearProducts"
            class="shadow focus:border-blue-500 block w-full sm:text-sm border-blue-300 rounded-md"
          >
            <option v-for="tag in tags" :key="tag._id">{{ tag.name }}</option>
          </select>
        </div>
        <!-- End Filter -->

        <div class="py-4">
          <div class="grid grid-cols-4 md:grid-cols-12">
            <button @click="clearSelection" class="hidden sm:block w-full text-blue-700">
              Clear
            </button>
            <div class="hidden sm:block"></div>

            <div
              class="col-span-2 mx-4 sm:mx-0 md:col-span-5 cursor-pointer font-semibold"
              @click="setSort('title')"
            >
              Name
              <font-awesome-icon
                icon="chevron-down"
                id="title"
                class="chevron transition-transform duration-200"
              />
            </div>

            <div class="hidden md:block cursor-pointer font-semibold">Stock</div>

            <div class="hidden md:block cursor-pointer font-semibold" @click="setSort('type')">
              Type
              <font-awesome-icon
                icon="chevron-down"
                id="type"
                class="chevron transition-transform duration-200"
              />
            </div>

            <div
              class="md:col-span-2 hidden md:block cursor-pointer font-semibold"
              @click="setSort('vendor')"
            >
              Vendor
              <font-awesome-icon
                icon="chevron-down"
                id="vendor"
                class="chevron transition-transform duration-200"
              />
            </div>
            <div class="font-semibold text-gray-500 text-right pr-2">Published</div>
          </div>

          <div v-if="loading" class="my-10">
            <Loading :whiteSpace="false" />
          </div>
          <div v-if="numberOfProducts > 0">
            <div
              v-for="(product, index) in products"
              :key="product._id"
              class="grid grid-cols-5 md:grid-cols-12 py-2"
              :class="index % 2 ? 'bg-gray-100' : null"
            >
              <div
                class="hidden md:flex justify-center items-center relative py-3 hover:bg-blue-100"
              >
                <div
                  class="absolute bg-transparent z-1 w-full h-full cursor-pointer"
                  @click.exact="alterSelectedProducts(product)"
                  @click.shift="shiftSelect(product)"
                ></div>
                <div v-if="showTriangle(product)" class="absolute left-0 ml-2 text-red pulse">
                  <font-awesome-icon icon="exclamation-triangle" />
                </div>
                <input type="checkbox" class="productCheckBox w-4 h-4" :id="product._id" />
              </div>
              <router-link
                :to="'/admin/products/' + product._id"
                class="select-none grid relative items-center grid-cols-4 col-span-5 md:grid-cols-11 md:col-span-11 hover:bg-blue-100 text-gray-800"
              >
                <div
                  class="rounded overflow-hidden shadow mx-auto w-12 flex items-center justify-center"
                  :class="getBorderColor(product.color)"
                >
                  <img
                    v-if="product.image"
                    v-lazy="product.image"
                    :alt="`Image of ${product.title}`"
                    class="w-10"
                  />
                  <img
                    v-else-if="product.variants[0]"
                    v-lazy="product.variants[0].image"
                    :alt="`Image of ${product.title}'s variant`"
                    class="w-10"
                  />
                </div>

                <div
                  class="overflow-hidden whitespace-no-wrap col-span-3 md:col-span-5 my-auto font-medium"
                >
                  {{ product.title }}
                </div>

                <div class="hidden md:block overflow-hidden px-2 my-auto">
                  {{ getInventory(product) }}
                </div>
                <div class="hidden md:block overflow-hidden my-auto">
                  {{ product.type }}
                </div>
                <div class="overflow-x-hidden whitespace-no-wrap md:col-span-2 my-auto relative">
                  <span class="hidden md:block">{{ product.vendor }}</span>
                </div>
                <div class="text-right hidden md:block pr-2 text-gray-500">
                  <span v-if="product.publish">
                    {{ numOfVariantsPublished(product.variants) }}
                    / {{ product.variants.length }}
                  </span>
                  <span v-else> unpublished </span>
                </div>
                <div
                  v-if="warning(product)"
                  class="text-red absolute mr-2 md:mr-0 top-0 right-0 my-3 md:right-1 overflow-hidden"
                >
                  <font-awesome-icon icon="exclamation" />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- End List -->
    </div>
    <!-- Quick edit sidebar -->
    <transition name="expandRight">
      <PriceEdit
        v-if="showQuickEditor && !QEMinimized"
        :products="selectedProducts"
        @cancel="clearSelection"
        @minimize="QEMinimized = true"
      />
    </transition>
    <!-- End Quick edit sidebar -->
    <Pagination
      pageType="Product"
      :admin="admin"
      :selectedNumber="selectedNumber"
      :totalAvailable="totalAvailable"
      :limit="limit"
      :selectableLimits="selectableLimits"
      :currentPage="currentPage"
      @setCurrentPage="setCurrentPage"
      @setLimit="setLimit"
    />
    <PopUp v-if="showChooseTemplate" @close="showChooseTemplate = false">
      <ChooseTemplate />
    </PopUp>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading';
import DualButton from '@/components/shared/DualButton';

import PriceEdit from './priceEditor/PriceEditor';
import Importer from './ImportExport';
import Quickbooks from '@/components/Quickbooks.vue';
import Pagination from '@/components/shared/Pagination';
import PopUp from '@/components/shared/DesktopModal';
import ChooseTemplate from './ChooseTemplate.vue';
import { border as getBorderColor } from '@/assets/functions/getColor.js';

export default {
  data: () => {
    return {
      selectedProducts: [],
      showVendors: false,
      showCategories: false,
      showSubcategories: false,
      showTags: false,
      selectableLimits: [50, 100, 200, 1000],

      vendor: 'All Vendors',
      category: 'All Categories',
      subcategory: 'All Subcategories',
      tag: 'All Tags',

      selectedProduct: null,
      QEMinimized: false,
      qbLoading: false,
      minKey: 0,

      alterProductsButtonOptions: [
        {
          name: '+ Add product',
          text: 'Add a new product',
          buttonMethod: 'create',
        },
        {
          name: 'Sync product costs',
          text: 'Sync special pricing costs to current costs. This will execute for every product.',
          buttonMethod: 'sync',
        },
        {
          name: 'Delete selected',
          buttonMethod: 'delete',
          text:
            'Deleting products is irreversable. This action does not delete the products variants',
          bgColor: 'bg-red-300',
          bgHoverColor: 'bg-red-600',
        },
      ],
      sort: 'title',
      currentPage: 1,
      limit: 50,
      showChooseTemplate: false,
    };
  },
  computed: {
    totalAvailable() {
      return this.$store.getters['admin/getQueryTotal'];
    },
    selectedNumber() {
      return this.selectedProducts.length;
    },
    searchValue: {
      get() {
        return this.$store.getters['admin/getProductSearch'];
      },
      set(val) {
        this.$store.commit('admin/setProductSearch', val);
      },
    },
    numberOfProducts() {
      return this.products.length;
    },
    queryTotal() {
      return this.$store.getters['admin/getQueryTotal'];
    },
    loading: {
      get() {
        return this.$store.getters['admin/getLoading'];
      },
      set(val) {
        this.$store.commit('admin/setLoading', val);
      },
    },
    products() {
      return this.$store.getters['admin/getProducts'];
    },

    vendors() {
      const values = this.$store.getters['tags/getTags']({ type: 'Vendor' });

      values.unshift({
        _id: 1,
        name: 'All Vendors',
      });
      return values;
    },
    categories() {
      const values = this.$store.getters['tags/getTags']({ type: 'Category' });
      values.unshift({
        _id: 1,
        name: 'All Categories',
      });
      return values;
    },
    subcategories() {
      let values = this.$store.getters['tags/getTags']({ type: 'Subcategory' });
      if (this.category !== 'All Categories') {
        values = this.$store.getters['tags/getTags']({ type: 'Subcategory', value: this.category });
      }
      values.unshift({
        _id: 1,
        name: 'All Subcategories',
      });
      return values;
    },
    tags() {
      const values = this.$store.getters['tags/getTags']({ type: 'Tag' });
      values.unshift({
        _id: '1',
        name: 'All Tags',
      });
      return values;
    },
    showQuickEditor() {
      if (this.selectedProducts.length > 0) {
        return true;
      }
      return false;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  watch: {
    QEMinimized() {
      this.minKey++;
    },
    loading() {
      this.scrollToPriorLocation();
    },
  },
  methods: {
    getBorderColor: getBorderColor,
    handleButtonMethods(option) {
      switch (option.buttonMethod) {
        case 'create':
          this.createNewProduct();
          break;
        case 'sync':
          this.syncCosts();
          break;
        default:
          this.deleteSelected();
          break;
      }
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
      this.clearProducts();
    },
    setLimit(payload) {
      this.limit = payload;
      this.currentPage = 1;
      this.clearProducts();
    },

    setLoading(payload) {
      this.loading = payload;
    },
    createNewProduct() {
      this.showChooseTemplate = true;
    },
    showTriangle(product) {
      for (let i = 0; i < product.variants.length; i++) {
        if (!product.variants[i].quickbooksId) {
          return true;
        }
      }
      return false;
    },
    setSort(selection) {
      //return ;
      const chevrons = document.getElementsByClassName('chevron');
      chevrons.forEach((el) => {
        if (el.classList.contains('flip')) {
          el.classList.remove('flip');
        }
      });
      const selected = document.getElementById(selection);
      if (selection !== this.sort) {
        selected.classList.add('flip');
        this.sort = selection;
      } else {
        this.sort = `-${selection}`;
      }
      this.clearProducts();
    },

    alterSelectedProducts(product) {
      const checkbox = document.getElementById(product._id);
      if (checkbox.checked) {
        checkbox.checked = false;
        this.selectedProducts = this.selectedProducts.filter((el) => {
          return el._id !== product._id;
        });
      } else {
        checkbox.checked = true;
        this.selectedProducts.push(product);
      }
    },
    changeSelectedProduct(product) {
      if (this.selectedProduct === product) {
        this.$router.push('/admin/products/' + product._id);
      }
      this.selectedProduct = product;
      this.showQuickEditor = true;
    },
    shiftSelect(product) {
      if (this.selectedProducts.length === 0) {
        return;
      }

      const selectedIndex = this.products.indexOf(product);
      const lastIndex = this.products.indexOf(
        this.selectedProducts[this.selectedProducts.length - 1]
      );
      let first, last;
      if (lastIndex < selectedIndex) {
        first = lastIndex + 1;
        last = selectedIndex + 1;
      } else {
        (first = selectedIndex), (last = lastIndex);
      }

      const products = this.products.slice(first, last);
      products.forEach((el) => {
        this.alterSelectedProducts(el);
      });
    },
    clearSelection() {
      this.selectedProducts = new Array();
      const boxes = document.getElementsByClassName('productCheckBox');
      boxes.forEach((box) => {
        box.checked = false;
      });
    },
    buildQuery() {
      const query = {
        sort: this.sort,
        page: this.currentPage,
        limit: this.limit,
      };

      if (this.vendor != 'All Vendors') {
        query.vendor = this.vendor;
      }
      if (this.category != 'All Categories') {
        query.categories = this.category;
      }
      if (this.subcategory != 'All Subcategories') {
        query.subcategories = this.subcategory;
      }
      if (this.tag != 'All Tags') {
        query.tags = this.tag;
      }
      if (this.searchValue) {
        query.search = this.searchValue;
      }

      this.$router
        .push({
          name: this.$route.name,
          query,
        })
        .catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error);
          }
        });
    },

    fetchProducts() {
      this.buildQuery();
      const query = { ...this.$route.query };
      query.showLoader = true;
      this.$store.dispatch('admin/getProducts', query);
    },
    clearProducts() {
      this.$store.commit('admin/clearProducts');
      this.$store.commit('setScroll');
      this.selectedProducts = [];
      this.fetchProducts();
    },
    setPage() {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.limit = this.$route.query.limit ? this.$route.query.limit : 50;
      this.category = this.$route.query.categories
        ? this.$route.query.categories
        : 'All Categories';
      this.vendor = this.$route.query.vendor ? this.$route.query.vendor : 'All Vendors';
      this.subcategory = this.$route.query.subcategories
        ? this.$route.query.subcategories
        : 'All Subcategories';
      this.tag = this.$route.query.tags ? this.$route.query.tags : 'All Tags';
      this.searchValue = this.$route.query.search ? this.$route.query.search : null;

      this.$store.commit('admin/clearProducts');
      this.selectedProducts = [];
      this.fetchProducts();
    },
    getInventory(product) {
      let count = 0;
      product.variants.forEach((el) => {
        count += el.stock;
      });
      product.totalStock = count;
      return count;
    },

    warning(product) {
      if (product.totalStock < product.totalOnOrder) {
        return true;
      }
      return false;
    },

    syncCosts() {
      this.$store.dispatch('company/syncCosts');
    },

    async deleteSelected() {
      const response = prompt(
        `To confirm the deletion of ${this.selectedProducts.length} products, please type 'Delete ${this.selectedProducts.length} products'`
      );
      if (response === `Delete ${this.selectedProducts.length} products`) {
        this.$store.dispatch('admin/deleteProducts', this.selectedProducts);
      }
    },
    numOfVariantsPublished(variants) {
      return variants.reduce((a, b) => a + (b.publish ? 1 : 0), 0);
    },
    scrollToPriorLocation() {
      if (!this.loading) {
        const scroll = this.$route.query.scrollTo
          ? this.$route.query.scrollTo
          : this.$store.getters['getScroll'](this.$route.name);
        if (scroll) {
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: scroll,
              behavior: 'smooth',
            });
          }, 500);
        }
      }
    },
  },
  components: {
    Loading,
    Importer,
    PriceEdit,
    DualButton,
    Quickbooks,
    Pagination,
    PopUp,
    ChooseTemplate,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setScroll', { name: this.$route.name, position: parseInt(window.scrollY) });
    next();
  },
  mounted() {
    this.setPage();
  },
};
</script>
<style scoped>
.pulsing {
  animation: pulse 4s ease-in-out infinite;
}

.flip {
  transform: rotate(180deg);
}
</style>
