<!--
    Uploader.vue

    Component for uploading images. After the image has been successfuly
    saved to the database, the server sends back the path to the image
    inside of the response. This component then emits an event called
    setValues the parameter of the second implicit parameter that is 
    passed to the parent function "setValues" is of type
    {
      path: img.path,
      error: response.error,
      color: "green",
    }

    Error doesn't only contain an error, it simply contains the value
    message returned from the server.

    Example:
    <Uploader v-if="showUploader" @setValues="setValues" @uploadFinished="showUploader = false" @escape="showUploader = false"></Uploader>
-->

<template>
  <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-if="open"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full"
          :class="add_watermark ? 'grid grid-cols-2  max-w-4xl' : ' max-w-lg'"
        >
          <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4" v-show="add_watermark">
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-gray-900">Preview</h3>
              <div ref="preview_wrapper" class="relative overflow-hidden">
                <img src="" ref="preview_image" alt="" />
                <img
                  v-if="watermark_type == 'image'"
                  src="/images/icons/Logo-primary.png"
                  class="absolute"
                  :style="`width: ${(watermark.height / 374) * watermark.width}px; height: ${
                    watermark.height
                  }px; top: ${watermark.vertical}px; left: ${
                    watermark.horizontal
                  }px; transform: rotate(${watermark.rotation}deg); opacity: ${
                    watermark.opacity
                  }; filter: alpha(opacity=${watermark.opacity * 100});`"
                />
                <span
                  v-else
                  class="text-3xl opacity-50 absolute"
                  :style="`width: ${(watermark.height / 374) * watermark.width}px; height: ${
                    watermark.height
                  }px; top: ${watermark.vertical}px; left: ${
                    watermark.horizontal
                  }px; transform: rotate(${watermark.rotation}deg); color: ${
                    watermark.color
                  }; opacity: ${watermark.opacity}; filter: alpha(opacity=${
                    watermark.opacity * 100
                  });`"
                >
                  {{ watermark.text }}
                </span>
              </div>
              <div class="grid grid-cols-3 gap-2">
                <fieldset class="space-y-2">
                  <div class="flex items-center">
                    <input
                      id="watermark_image"
                      name="watermark_image"
                      type="radio"
                      v-model="watermark_type"
                      value="image"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      for="watermark_image"
                      class="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      image
                    </label>
                  </div>
                  <div class="flex items-center">
                    <input
                      id="watermark_text"
                      name="watermark_text"
                      type="radio"
                      v-model="watermark_type"
                      value="text"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      for="watermark_text"
                      class="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      text
                    </label>
                  </div>
                </fieldset>

                <div class="flex flex-col">
                  <label for="watermark_height" class="block text-sm font-medium text-gray-700"
                    >height</label
                  >
                  <input
                    type="number"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark_height"
                    v-model="watermark.height"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="watermark_top" class="block text-sm font-medium text-gray-700"
                    >verticle</label
                  >
                  <input
                    type="number"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark_top"
                    v-model="watermark.vertical"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="watermark_right" class="block text-sm font-medium text-gray-700"
                    >horizontal</label
                  >
                  <input
                    type="number"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark_right"
                    v-model="watermark.horizontal"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="watermark_rotation" class="block text-sm font-medium text-gray-700"
                    >rotation</label
                  >
                  <input
                    type="number"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark_rotation"
                    v-model="watermark.rotation"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="watermark_opacity" class="block text-sm font-medium text-gray-700"
                    >opacity</label
                  >
                  <input
                    type="number"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark_opacity"
                    v-model="watermark.opacity"
                  />
                </div>
                <div class="flex flex-col" v-if="watermark_type == 'text'">
                  <label for="watermark-text" class="block text-sm font-medium text-gray-700"
                    >text</label
                  >
                  <input
                    type="text"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark-text"
                    v-model="watermark.text"
                  />
                </div>
                <div class="flex flex-col" v-if="watermark_type == 'text'">
                  <label for="watermark-color" class="block text-sm font-medium text-gray-700">
                    color
                  </label>
                  <input
                    type="text"
                    class="no-arrows border focus:border-blue-500 block w-full rounded-md px-2 sm:text-sm border-gray-300"
                    id="watermark-color"
                    v-model="watermark.color"
                  />
                </div>
              </div>
            </div>
          </div>
          <form
            @submit.prevent="handleUpload"
            enctype="multipart/form-data"
            class="text-left flex flex-col justify-between"
          >
            <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
                    <path
                      fill-rule="evenodd"
                      d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    class="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                    v-if="title"
                  >
                    Upload Image
                  </h3>
                  <input type="file" name="photo" class="styled w-64 my-6" @change="fileChanged" />
                  <div class="text-center md:text-right"></div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex justify-between sm:px-6 items-center">
              <fieldset class="">
                <div class="relative flex items-start">
                  <div class="min-w-0 flex-1 text-sm leading-6">
                    <label for="add_watermark" class="font-medium text-gray-900">watermark</label>
                  </div>
                  <div class="ml-3 flex h-6 items-center">
                    <input
                      id="add_watermark"
                      name="add_watermark"
                      type="checkbox"
                      v-model="add_watermark"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                </div>
              </fieldset>
              <div class="text-center md:text-right">
                <button
                  @click="$emit('close')"
                  type="button"
                  class="inline-flex w-full justify-center rounded-l-md bg-red-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="mt-3 inline-flex w-full justify-center rounded-r-md bg-primary hover:blue-600 text-white px-3 py-2 text-sm font-semibold shadow-sm sm:mt-0 sm:w-auto"
                >
                  {{ buttonText }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  name: 'Uploader',
  props: {
    title: { type: Boolean, default: true },
    buttonText: { type: String, default: 'Upload' },
    variant: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      file: null,
      thumbnail: null,
      path: '',
      thumbnailPath: '',
      add_watermark: false,
      watermark_type: 'image',
      watermark: {
        height: 75,
        width: 500,
        horizontal: 0,
        vertical: 25,
        rotation: 0,
        text: 'PCS',
        color: '#005785',
        opacity: 0.3,
      },
    };
  },
  methods: {
    fileChanged(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      if (this.file.size > 2000000) {
        this.$store.commit('setError', {
          message: 'File Uploaded is too large, please reduce the file size',
          color: 'red',
        });
        this.$emit('close');
        return;
      }
      const fr = new FileReader();
      fr.onload = () => {
        this.$refs.preview_image.src = fr.result;
      };
      fr.readAsDataURL(this.file);
    },

    handleUpload() {
      if (this.file === null) {
        this.$emit('noFileSave', null);
        return;
      }
      const formData = new FormData();

      if (this.add_watermark) {
        html2canvas(this.$refs.preview_wrapper)
          .then((canvas) => {
            canvas.toBlob(async (blob) => {
              formData.append('photo', blob, this.fileName);
              this.upload(formData);
            });
          })
          .catch((error) => {
            console.log('ERROR SAVING FESTIVAL IMAGE', error);
          });
      } else {
        formData.append('photo', this.file, this.fileName);
        this.upload(formData);
      }
    },

    upload: async function (formData) {
      try {
        let response = await this.$store.dispatch('uploadSingle', formData);
        this.thumbnail = `${response[0].replace(/1000/g, 300)}`;
        this.path = `${response[0]}`;
        this.file = null;
      } catch (error) {
        console.log(error);
        this.$store.commit('setError', {
          message: error,
          color: 'red',
        });
        return;
      }
      this.$emit('setValues', { path: this.path, thumbnail: this.thumbnail });
      this.$emit('uploadFinished');
    },
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
