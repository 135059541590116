const axios = require('axios');
module.exports = async (variant, vendor) => {
  const pricing = new Array();
  try {
    const response = await axios.get('/api/v1/tags?type=Vendor');
    let tags = response.data;
    console.log(variant, vendor, tags);
    tags.some((el) => {
      if (el.name === vendor) {
        pricing.push(el.tierOne ? el.tierOne : 0.9);
        pricing.push(el.tierTwo ? el.tierTwo : 0.9);
        pricing.push(el.tierThree ? el.tierThree : 0.9);
        pricing.push(el.tierFour ? el.tierFour : 0.9);
        pricing.push(el.tierFive ? el.tierFive : 0.8);
        pricing.push(el.tierSix ? el.tierSix : 0.8);
        return true;
      }
    });
  } catch (error) {
    console.log(error);
  }

  if (pricing.length === 0) {
    pricing.push(0.9);
    pricing.push(0.9);
    pricing.push(0.9);
    pricing.push(0.9);
    pricing.push(0.8);
    pricing.push(0.8);
  }

  variant.tierOne = (variant.msrp * pricing[0]).toFixed(2) * 1;
  if (variant.tierOne < variant.cost) {
    variant.tierOne = (variant.cost * 1.1).toFixed(2);
  }
  variant.tierTwo = (variant.msrp * pricing[1]).toFixed(2) * 1;
  if (variant.tierTwo < variant.cost) {
    variant.tierTwo = (variant.cost * 1.1).toFixed(2);
  }
  variant.tierThree = (variant.msrp * pricing[2]).toFixed(2) * 1;
  if (variant.tierThree < variant.cost) {
    variant.tierThree = (variant.cost * 1.1).toFixed(2);
  }
  variant.tierFour = (variant.msrp * pricing[3]).toFixed(2) * 1;
  if (variant.tierFour < variant.cost) {
    variant.tierFour = (variant.cost * 1.1).toFixed(2);
  }
  variant.tierFive = (variant.msrp * pricing[4]).toFixed(2) * 1;
  if (variant.tierFive < variant.cost) {
    variant.tierFive = (variant.cost * 1.1).toFixed(2);
  }
  variant.tierSix = (variant.msrp * pricing[5]).toFixed(2) * 1;
  if (variant.tierSix < variant.cost) {
    variant.tierSix = (variant.cost * 1.1).toFixed(2);
  }
};
